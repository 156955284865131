import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { NgForm } from '@angular/forms';

declare function closeLeadAssignModel(): any;
declare function closeCallLeadFeedbackModel(): any;
declare function enableStatus(): any;
@Component({
  selector: 'app-calling-data',
  templateUrl: './calling-data.component.html',
  styleUrls: ['./calling-data.component.css']
})
export class CallingDataComponent implements OnInit {
  callStatusFieldCheck: boolean = false;
  str: any = null;
  selectedUserId: any;
  callLeads: any[];
  users: Array<any> = [];
  userList: any = [];
  childUsers:any =[];
  selectedIds: Array<any> = [];
  selectedId: Array<any> = [];
  userId: any;
  sourceNameList:Array<any> = [];
  id=localStorage.getItem('id')
  ROLE: boolean;
  userRole: string;
  dropdownSettings: IDropdownSettings = {};
  page: number = 0;
  private pageSize: number = 20;
  inputDateFromString: any;
  inputDateToString: any;
  inputProjectNameString: any;
  onchangeAgentString: any;
  projectNameList:Array<any> = [];
  public config = {
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0
  };

  acceptCallStatus: string[] = new Array("Followup", "Meeting Planned", "Visit Planned", "Not Connected", "Callback", "Visit Done",  "Followup Done,Not Responding","Metting Done","Resale","Virtual Meeting Planned","Virtual Meeting Done");
  // rejectCallStatus: string[] = new Array("Not Interested", "Invalid Number", "Already Purchased");
  rejectCallStatus: string[] = new Array(
    "Invalid Number",
    "Not Interested",
    "Already Purchased",
    "Budget Issue",
    "Location Issue",
    "Loan Issue",
    "Not Responding",
    "Plan Postponed",
    "Blocked",
    "Broker"
  );
  leadsStatus: string = "";
  callStatus: string = "";
  statusCheck: boolean = false;
  callStatusArray: string[];

  callStatusBoolean: boolean = false;
  feedbackUser: any = {};

  lead: any = {}

  status: any[] = [
    { id: 1, name: "OPEN" },
    { id: 2, name: "DECLINE" },
  ];

  private json_obj = {
    'startDate': null,
    'endDate': null,
    'userId': null,
    'status': null,
    'assignedToIds': null,
    // 'project':null,
  };
 
  onchangeStatusString: any;
  usersList: any[];

  constructor(private auth: AuthService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.auth.startLoader();
    this.userRole = localStorage.getItem("role");
    if (this.userRole === 'ADMIN') {
      this.ROLE = true;
    } else {
      this.ROLE = false;
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      // selectAllText: 'Select All',
      // unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      // allowSearchFilter: true
    };

    this.getFilteredCallLeadsByPage(this.json_obj);

    this.auth.getSalesAndActiveUsers().subscribe(
      data => {
        this.childUsers = data;
      }
    )

    this.getAgents();
    this.getProjects();
  }

  selectAll(isChecked: boolean) {
    var input = document.getElementsByTagName("input");
    if (isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === false
        ) {
          input[i].checked = true;
        }
      }
      for (var i = 0; i < this.callLeads.length; i++) {
        var obj = {};
        obj["leadsId"] = this.callLeads[i].id;
        this.selectedIds.push(obj);
      }
      console.log("ids : ", this.selectedIds);
      console.log("idd : ", this.selectedId);
    }
    if (!isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        var obj = {};
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === true
        ) {
          input[i].checked = false;
        }
      }
      console.log("ids : ", this.selectedIds);
      console.log("idd : ", this.selectedId);
    }
  }
  getAgents() {
    this.auth.getUsers().subscribe((data) => {
      var list: Array<any> = [];
      for (let user of data) {
        var obj: any = {};
        obj["id"] = user.id;
        obj["name"] = user.firstName + " " + user.lastName;
        list.push(obj);
      }
      this.userList = list;
    });
  }
  

  getCallLeads() {
    this.auth.getCallLeads().subscribe(
      data => {
        this.auth.stopLoader();
        this.callLeads = data;
      },
      err => {
        this.auth.stopLoader();
        alert(err);
      }
    )
  }
  onChangeUserDropBoxOfMultipleAssign(user_id) {
    this.userId = user_id;
  }
  onCheck(leadId, event) {
    var arrObj = {};
    arrObj["leadsId"] = leadId;
    if (event.target.checked) {
      this.selectedIds.push(arrObj);
    } else {
      let index = this.selectedIds.findIndex(x => x['leadsId'] === leadId)
      this.selectedIds.splice(index, 1);
    }
  }
  assignMultipleLead() {
    this.auth.startLoader();
    console.log("length" + this.selectedIds.length);
    
    if (this.selectedIds.length > 0) {
      console.log("length=====" + this.selectedIds.length);
      
      this.auth.assignLeads(this.userId, this.selectedIds).subscribe(
        data => {
          this.selectedIds = [];
          this.auth.stopLoader();
          alert("Saved Successfully");
          window.location.reload();
          closeLeadAssignModel();
          
          this.getCallLeads();
          
        },
        error => {
          this.auth.stopLoader();
          this.getCallLeads();
          alert("Error: " + error['message']);
        }
      );
    } else {
      this.auth.stopLoader();
      closeLeadAssignModel();
      alert("Please select leads.");
    }
  }
  declineCallLeads() {
    this.auth.startLoader();
    if (this.selectedIds.length > 0) {
      this.auth.declineCallLeads(this.selectedIds).subscribe(
        data => {
          this.selectedIds = [];
          this.auth.stopLoader();
          this.getCallLeads();
          alert(data['message']);
        },
        error => {
          this.auth.stopLoader();
          this.getCallLeads();
          alert(error);
        }
      );
    } else {
      this.auth.stopLoader();
      alert("Please select leads.")
    }
  }
  assignLead(userId, leadId) {
    let leadsArray = [
      { "leadsId": leadId },
    ];
    this.auth.startLoader();
    this.auth.assignLeads(userId, leadsArray).subscribe(
      data => {
        this.auth.stopLoader();
        alert(data['message'])
      },
      error => {
        this.auth.stopLoader();
        alert(error);
      }
    );
  }

  importCallingData() {
    window.open('import/leads/calling')
  }
  // onChangeSize(event) {
  //   if (event != "") {
  //     this.pageSize = event;
  //   } else {
  //     this.pageSize = 20;
  //   }
  // }
  itemCount: any;
  getFilteredCallLeadsByPage(json_obj) {
    this.auth.startLoader();

    const pageNo = this.page > 1 ? this.page - 1 : 0;
 

    // Use a persistent user ID (store it in a component variable to retain it between page changes)
    if (json_obj.userId) {
        this.selectedUserId = json_obj.userId; // Store userId for persistence
    }
    const userIdToUse = this.selectedUserId ? this.selectedUserId : this.id; // Use the persisted userId or default to this.id

    console.log("---------------- " + userIdToUse + "-------------- pageNo + " + pageNo);

    // Call the appropriate API based on whether userId was provided initially or not
    if (this.selectedUserId) {
        this.auth.filterCallLead1(userIdToUse, json_obj.project, this.pageSize, pageNo).subscribe(
            data => {
                this.callLeads = data['content'];
                this.config.totalItems = data['totalElements'];
                this.itemCount = data['totalElements'];
                this.config.itemsPerPage = this.pageSize;
                this.auth.stopLoader();
            },
            error => {
                this.auth.stopLoader();
                console.error('Error fetching filtered call leads:', error);
            }
        );
    } else {
        this.auth.callLeadfilter(userIdToUse, json_obj.project, this.pageSize, pageNo).subscribe(
            data => {
                this.callLeads = data['content'];
                this.config.totalItems = data['totalElements'];
                this.itemCount = data['totalElements'];
                this.config.itemsPerPage = this.pageSize;
                this.auth.stopLoader();
            },
            error => {
                this.auth.stopLoader();
                console.error('Error fetching call leads:', error);
            }
        );
    }
}



  pageChanged(event) {
    this.auth.startLoader();
    this.page = event;  
    this.config.currentPage = event;
    this.getFilteredCallLeadsByPage(this.json_obj)
  }

  // onsubmitFilter(event) {
  //   this.selectAll(false);
  //   this.page = 0;
  //   let formFilter = event.value;
  //   let statusStr: any;
  //   let agentID: any;
  //   let assignedTo:any;

  //   if (formFilter.lStatus.length > 0) {
  //     for (var str of formFilter.lStatus) {
  //       statusStr = str.name;
  //     }
  //   } else {
  //     statusStr = null;
  //   }
  //   if (formFilter.lAgent.length > 0) {
  //     for (var str of formFilter.lAgent) {
  //       agentID = str.id;
  //     }
  //   } else {
  //     agentID = 0;
  //   }
  //   if (formFilter.lAssignedTo.length > 0) {
  //     for (var str of formFilter.lAssignedTo) {
  //       assignedTo.push(str.id);
  //     }
  //   } else {
  //     assignedTo = [];
  //   }
  
  //   this.json_obj = {
  //     startDate: this.auth.toTimestamp(formFilter.rdFrom),
  //     endDate: this.auth.toTimestamp(formFilter.rdTo),
  //     status: statusStr,
  //     userId: agentID,
  //     assignedToIds: assignedTo,
  //     // rFromDate: this.auth.toTimestamp(formFilter.rdFrom),
  //     // rToDate: this.auth.toTimestamp(formFilter.rdTo),
  //     // aFromDate: this.auth.toTimestamp(formFilter.adFrom),
  //     // aToDate: this.auth.toTimestamp(formFilter.adTo),
  //     // csFromDate: this.auth.toTimestamp(formFilter.csdFrom),
  //     // csToDate: this.auth.toTimestamp(formFilter.csdTo),
  //     // statusList: status,
  //     // callStatusList: callStatus,
  //     // assignedByIds: assignedBy,
  //     // assignedToIds: assignedTo,
  //     // projectsString: project,
  //     // sourceString: [],
  //     // orderBy: formFilter.orderBy,
  //     // sortBy: formFilter.sortBy,
  //   };
    

  //   console.log(this.json_obj);

  //   this.getFilteredCallLeadsByPage(this.json_obj)
  // }

  onsubmitFilter(form: NgForm) {
    const userId = form.value.lAgent ? form.value.lAgent[0].id : null;  // Capture userId if selected
    const project = form.value.lProject ? form.value.lProject[0].name : null;
    const source = form.value.lSource ? form.value.lSource[0].name : null; // If you add a source filter
    const pageNo = this.page > 1 ? this.page - 1 : 0;
    
    const json_obj = {
      userId: userId,   // Pass userId in the object
      project: project,
      source: source
    };

    this.getFilteredCallLeadsByPage(json_obj);  // Call the method with the json_obj
}



  private filterFieldsData(event: any) {
    this.auth.startLoader();
    event.preventDefault();
    let target = event.target;
    if (target.querySelector('#agent').value != null) {
      this.onchangeAgentString = target.querySelector('#agent').value;
    }
    else {
      this.onchangeAgentString = null;
    }
    if (target.querySelector('#status').value != null) {
      this.onchangeStatusString = target.querySelector('#status').value;
    }
    else {
      this.onchangeStatusString = null;
    }
    if (target.querySelector('#datefrom').value != null) {
      this.inputDateFromString = target.querySelector('#datefrom').value;
      var datePipe = new DatePipe("en-US");
      this.inputDateFromString = datePipe.transform(this.inputDateFromString, 'dd-MM-yyyy');
    }
    else {
      this.inputDateFromString = null;
    }
    if (target.querySelector('#dateto').value != null) {
      this.inputDateToString = target.querySelector('#dateto').value;
      var datePipe = new DatePipe("en-US");
      this.inputDateToString = datePipe.transform(this.inputDateToString, 'dd-MM-yyyy');
    }
    else {
      this.inputDateToString = null;
    }

  }


  // feedbackStatus(str) {
  //   this.leadsStatus = str;
  //   if (str === 'accepted') {
  //     this.saveAndAssigneCallLeadsToLeads(this.lead, this.feedbackUser['id']);
  //     this.callStatusArray = this.acceptCallStatus;
  //     this.callStatusBoolean = false;
  //   } else if (str === 'rejected') {
  //     this.callStatusBoolean = true;
  //     this.statusCheck = false;
  //     // this.callStatusArray = this.rejectCallStatus;
  //     // enableStatus();
  //   } else {
  //     this.callStatusArray = [];
  //     this.leadsStatus = null;
  //     this.callStatusBoolean = false;
  //     this.statusCheck = false;
  //     alert('Please select status');
  //   }
  // }
  feedbackStatus(str) {
    this.leadsStatus = str;
    // this.lead.id = leadId; // Set the lead ID here
    if (str === 'Open') {
        this.saveAndAssigneCallLeadsToLeads(this.lead, this.feedbackUser['id']);
        this.callStatusArray = this.acceptCallStatus;
        this.callStatusBoolean = false;
    } 
    // else if (str === 'rejected') {
    //     this.callStatusBoolean = true;
    //     this.statusCheck = false;
    //   this.callStatusArray = this.rejectCallStatus;
    //   enableStatus();
      else if (str === 'Decline') {
        this.saveAndAssigneCallLeadsToLeads(this.lead, this.feedbackUser['id']);
            this.callStatusBoolean = true;
            this.callStatusFieldCheck=true;
            this.statusCheck = true;
            this.callStatusArray = this.rejectCallStatus;
            enableStatus();
      
    } else {
        this.callStatusArray = [];
        this.leadsStatus = null;
        this.callStatusBoolean = false;
        this.statusCheck = false;
        alert('Please select status');
    }
}




  //   leadStatusUpdate(obj, userObj) {
  //     this.lead = obj;
  //     this.feedbackUser = userObj;
  //     this.leadsStatus = "";
  //     this.callStatus = "";
  //     this.statusCheck = false;
  //     this.callStatusBoolean = false;
  //     console.log("Lead Object:", this.lead);
  // console.log("Lead ID:", this.lead.id);
  // console.log("testtttttttttt"+this.lead.user)
  //   }
    leadStatusUpdate(obj, userObj) {
      this.lead = obj;
      this.feedbackUser = userObj;
      this.leadsStatus = "";
      this.callStatus = "";
      this.statusCheck = false;
      this.callStatusBoolean = false;
      this.callStatusFieldCheck = false;
    }

  saveAndAssigneCallLeadsToLeads(leads, userId) {
    this.auth.startLoader()
    this.auth.saveAndAssignLeads(leads, userId).subscribe(
      data => {
        this.auth.stopLoader();
        this.lead = data;
        this.statusCheck = true;
      }
    )
  }

  feedbackCallStatus(str) {
    this.callStatus = str;
    if (this.callStatus != "") {
      this.callStatusBoolean = true;
    } else {
      this.callStatusBoolean = false;
      this.callStatus = null;
      alert('Please select call status');
    }
  }
  saveRemark(event) 
  {
    var date: any;
    var remark: string = "";
    let target = event.target;
  
    var feedbackObj: any = {};
  
    // Log to check event target and remark area
    console.log("Event target:", target);
  
    if (target.querySelector("#remarkArea").value != null) {
      remark = target.querySelector("#remarkArea").value;
    } else {
      remark = "";
    }
  
    // Log the remark value
    console.log("Remark:", remark);
   feedbackObj["id"]= this.lead.leads.id;
    feedbackObj["message"] = remark;
    feedbackObj["status"] = this.leadsStatus;
    feedbackObj["callStatus"] = this.callStatus;
    feedbackObj["user"] = this.feedbackUser;
  
    // Log the feedback object before date and status checks
    console.log("Feedback object initialized:", feedbackObj +"test"+JSON.stringify(this.lead.leads.id));
  
    if (this.leadsStatus == "open") {
      if (target.querySelector("#chooseDate") != null) {
        date = target.querySelector("#chooseDate").value;
        date = this.auth.toDateAndTimeTimestamp(date);
      } else {
        alert("Remark and date can not be null");
        return;
      }
  
      
      // Log date value
      console.log("Date selected:", date);
  
      if (
        this.callStatus === "Visit Done" ||
        this.callStatus === "Visited" ||
        this.callStatus === "Virtual Meeting Done"
      ) {
        if (this.callStatus === "Visit Done") {
          feedbackObj["visitStatus"] = true;
          feedbackObj["callStatusDate"] = date;
        } else if (this.callStatus === "Virtual Meeting Done") {
          feedbackObj["virtualMeetingStatus"] = true;
          feedbackObj["callStatusDate"] = date;
        }
        feedbackObj["callStatusDate"] = this.auth.toTimestamp(date);
      } else {
        if (this.callStatus == "Visit Planned") {
          feedbackObj["callStatusDate"] = date;
        } else if (this.callStatus == "Callback") {
          feedbackObj["callStatusDate"] = date;
        } else if (this.callStatus == "Meeting Planned") {
          feedbackObj["callStatusDate"] = date;
        } else if (this.callStatus == "Not Picked") {
          feedbackObj["callStatusDate"] = date;
        } else if (this.callStatus == "Followup") {
          feedbackObj["callStatusDate"] = date;
        } else if (this.callStatus == "Virtual Meeting") {
          feedbackObj["callStatusDate"] = date;
        }
        else if (this.callStatus == "Followup Done,Not Responding") {
          feedbackObj["callStatusDate"] = date;
        }
      }
      feedbackObj["callStatusDate"] = this.auth.toTimestamp(date);
    }
    if (target.querySelector("#chooseDate") != null) {
      date = target.querySelector("#chooseDate").value;
      date = this.auth.toDateAndTimeTimestamp(date);
    } 
    feedbackObj["callStatusDate"] = this.auth.toTimestamp(date);
  
    // Log the final feedback object before making the API call
    console.log("Final feedback object:", feedbackObj);
  
    if (remark.trim().length > 0) {
      this.auth.startLoader();
      this.auth.saveLeadsFeedback(this.lead.leads.id, feedbackObj).subscribe(
        (data) => {
          // Log the success response data
          console.log("API Response:", data);
  
          this.updateCallLeadsAndReport(this.json_obj);
          this.auth.stopLoader();
          alert(data["output"]);
        },
        (err) => {
          // Log the error
          console.error("API Error:", err);
  
          this.auth.stopLoader();
          alert("Something wrong try again.");
        }
      );
    } else {
      alert("Remark should not be empty.");
    }
  }
  

  updateCallLeadsAndReport(remark) {
    var callLeadsObj: any = {};
    callLeadsObj['feedback'] = remark;
    callLeadsObj['status'] = this.leadsStatus;
    callLeadsObj['updateDate'] = new Date().getTime();
    callLeadsObj['user'] = this.feedbackUser;
    callLeadsObj['rejected'] = false;
    callLeadsObj['phone'] = this.lead['phone'];
    this.auth.updateCallLeadsAndReport(callLeadsObj).subscribe(
      data => {
        this.auth.stopLoader();
        closeCallLeadFeedbackModel();
        alert(data['output']);
      },
      err => {
        this.auth.stopLoader();
        closeCallLeadFeedbackModel();
        alert('Something wrong.');
      }
    )
  }
  getProjects() {
    this.auth.getProjects().subscribe((data) => {
      var list: Array<any> = [];
      for (let project of data) {
        var obj: any = {};
        obj["id"] = project.id;
        obj["name"] = project.name;
        list.push(obj);
      }
      JSON.stringify(obj);
      this.projectNameList = list;
    });
  }

}