import { Component, OnInit, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterTestingModule } from '@angular/router/testing';
import { DatePipe } from '@angular/common';
import * as moment from 'moment'
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})

@NgModule({
  imports: [
    NgxUiLoaderModule,
    HttpClientModule,
    RouterTestingModule
  ],
  providers: [
    DatePipe
  ]
})
export class AdminComponent implements OnInit {

  constructor(private auth: AuthService, private router: Router) { }

  name = localStorage.getItem('firstName') + " " + localStorage.getItem('lastName');
  address = localStorage.getItem('address');
  leadsCount: number = 0;
  totalLeadsCount: number = 0;
  openLeadsCount: number = 0;
  leadsReportCount: number = 0;
  meetingCount: number = 0;
  junkLeadsCount: number = 0;
  todatAttendanceCount: number = 0;
  userRole: string;
  mobile: string = localStorage.getItem('mobile');
  newId=localStorage.getItem('id');
  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.auth.startLoader();
    this.tokenChek();
  }

  tokenChek() {
    this.auth.userTokenCheck().subscribe(
      data => {
        if (data['valid']) {
          this.getLeadsCount();
          this.getOpenLeadsCount();
          this.getLeadsReport();
          this.getMeetingCount();
          this.getUserMonthAttendanceCount();
          this.getFilteredCallLeadsByPage(null);
        } else {
          localStorage.clear();
          this.router.navigate(['/'])
          this.auth.stopLoader();
        }
      },
      error => {
        localStorage.clear();
        this.router.navigate(['/'])
        this.auth.stopLoader();
      }
    )
  }

  getLeadsCount() {
    this.auth.getLeadsCount().subscribe(
      data => {
        this.leadsCount = data['count'];
      }
    );
  }

  getOpenLeadsCount() {
    this.auth.getOpenLeadsCounts().subscribe(
      data => {
        this.openLeadsCount = data['count'];
      }
    );
  }

  getLeadsReport() {
    this.auth.getLeadsReportsCount().subscribe(
      data => {
        this.auth.stopLoader();
        this.leadsReportCount = data['count'];
      },
      error => {
        this.auth.stopLoader();
      }
    );
  }

  getAttendanceCount() {
    this.auth.getTodayAttendanceList().subscribe(
      data => {
        this.auth.stopLoader();
        this.todatAttendanceCount = data.length;
      },
      error => {
        this.auth.stopLoader();
      }
    );
  }

  getUserMonthAttendanceCount() {
    var id = localStorage.getItem('id');
    this.auth.getUserAttendanceListForManager(id).subscribe(
      data => {
        this.auth.stopLoader();
        this.todatAttendanceCount = data.length;
      },
      error => {
        this.auth.stopLoader();
      }
    );
  }

  getMeetingCount() {
    var id = localStorage.getItem('id');
    this.auth.getMeetingDetailsByManager(id).subscribe(
      data => {
        this.auth.stopLoader();
        this.meetingCount = data.length;
      },
      error => {
        this.auth.stopLoader();
      }
    );
  }

  callLeadsCount: number = 0;
  filteredLeadsCount:number =0;
  // getFilteredCallLeadsByPage(jsonObj) {
  //   this.auth.filterCallLeadsByPage(jsonObj, 0, 20).subscribe(
  //     data => {
  //       this.callLeadsCount = data['totalElements'];
  //       this.auth.stopLoader();
  //     },
  //     (error) => {
  //       this.auth.stopLoader();
  //     }
  //   );
  // }

    getFilteredCallLeadsByPage(jsonObj) {
    this.auth.callLeadfilter(this.newId,jsonObj, 20,0).subscribe(
      data => {
        this.callLeadsCount = data['totalElements'];
        this.filteredLeadsCount = data['content'].length;
        console.log("checkkkkk"+this.callLeadsCount);
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }
}
