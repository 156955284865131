import { Component, OnInit, NgModule } from "@angular/core";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { RouterTestingModule } from "@angular/router/testing";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { Users } from "src/app/model/users.model";
import { AuthService } from "src/app/services/auth.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";

declare function closeLeadFeedbackModel(): any;
declare function enableStatus(): any;
declare function disableStatus(): any;

@Component({
  selector: "app-openlead",
  templateUrl: "./openlead.component.html",
  styleUrls: ["./openlead.component.css"],
})
@NgModule({
  imports: [
    NgxUiLoaderModule,
    HttpClientModule,
    RouterTestingModule,
    HttpClient,
  ],
  providers: [DatePipe],
})
export class OpenleadComponent implements OnInit {
  userList: Users[];
  openLeads: any[];
  feedbackList: any[];

  onchangeCallStatusString: any;
  onchangeAgentString: any;
  onchangeSourceString: any;
  onchangeVisitStatus: any;

  inputDateFromString: any;
  inputDateToString: any;
  inputProjectNameString: any;
  sourceString: any[];
  projectNameString: any[];

  public config = {
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0,
  };
  private page: number = 1;
  private pages: Array<number>;
  private leadsReports: Array<any>;
  private filterStr: any;
  private exportStr: any;

  ROLE: boolean;
  userRole: string;

  lead: any = {};

  acceptCallStatus: string[] = new Array(
    "Followup",
    "Callback",
    "Not Connected",
    "Visit Planned",
    "Meeting Planned",
    "Virtual Meeting Planned",
    "Visit Done",
    "Meeting Done",
    "Virtual Meeting Done"
  );

  rejectCallStatus: string[] = new Array(
    "Invalid Number",
    "Not Interested",
    "Already Purchased",
    "Budget Issue",
    "Location Issue",
    "Loan Issue",
    "Followup Done, Not Responding",
    "Plan Postponed"
  );

  callStatusList: any[] = [
    { id: 1, name: "Followup" },
    { id: 2, name: "Callback" },
    { id: 3, name: "Not Connected" },
    { id: 4, name: "Visit Planned" },
    { id: 5, name: "Meeting Planned" },
    { id: 6, name: "Virtual Meeting Planned" },
    { id: 7, name: "Visit Done" },
    { id: 8, name: "Meeting Done" },
    { id: 9, name: "Virtual Meeting Done" },
  ];

  leadsStatus: string = "";
  callStatus: string = "";
  statusCheck: boolean = false;
  callStatusArray: string[];
  callStatusBoolean: boolean = false;
  feedbackUser: any = {};

  MOBILE: boolean;
  mobile: string = localStorage.getItem("mobile");

  private json_obj = {
    rFromDate: null,
    rToDate: null,
    aFromDate: null,
    aToDate: null,
    csFromDate: null,
    csToDate: null,
    statusList: ["OPEN"],
    callStatusList: null,
    assignedByIds: null,
    assignedToIds: null,
    projectsString: null,
    sourceString: null,
    orderBy: "DESC",
    sortBy: "receivedOn",
  };

  dropdownSettings: IDropdownSettings = {};
  activeUsers: any[];
  callStatusFieldCheck: boolean = false;
  isValid: boolean = false;
  projectNameList: any[];

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      idField: "id",
      textField: "name",
      itemsShowLimit: 1,
    };

    this.isValid = localStorage.getItem("isValid") == "YES";

    this.auth.startLoader();
    this.getFilteredLeadsReportByPage(this.json_obj);

    this.getAgents();

    this.getProjects();

    this.auth.getSalesAndActiveUsers().subscribe((data) => {
      this.activeUsers = data;
    });

    this.auth.getProjectNameString().subscribe((data) => {
      this.projectNameString = data;
    });
  }

  getProjects() {
    this.auth.getProjects().subscribe((data) => {
      var list: Array<any> = [];
      for (let project of data) {
        var obj: any = {};
        obj["id"] = project.id;
        obj["name"] = project.name;
        list.push(obj);
      }
      this.projectNameList = list;
    });
  }

  getAgents() {
    this.auth.getUsers().subscribe((data) => {
      var list: Array<any> = [];
      for (let user of data) {
        var obj: any = {};
        obj["id"] = user.id;
        obj["name"] = user.firstName + " " + user.lastName;
        list.push(obj);
      }
      this.userList = list;
    });
  }

  getLeadsReportByPage() {
    this.filterStr = "normal";
    this.auth.getOpenLeadsByPage(this.page).subscribe(
      (data) => {
        this.openLeads = data["content"];
        this.config.totalItems = data["totalElements"];
        // this.pages = new Array(data['totalPages']);
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  pageChanged(event) {
    this.auth.startLoader();
    this.page = event;
    this.config.currentPage = event;
    if (this.filterStr === "normal") {
      this.getLeadsReportByPage();
    } else if (this.filterStr === "filter") {
      this.getFilteredLeadsReportByPage(this.json_obj);
    }
  }

  setPage(i, $event: any) {
    this.auth.startLoader();
    event.preventDefault();
    this.page = i;
    this.getLeadsReportByPage();
  }

  assignLeadsToOtherUser(userId, leadId) {
    this.auth.startLoader();
    let leadsArray = [{ leadsId: leadId }];
    this.auth.startLoader();
    this.auth.assignLeads(userId, leadsArray).subscribe(
      (data) => {
        this.auth.stopLoader();
        alert(data["message"]);
      },
      (error) => {
        this.auth.stopLoader();
        alert(error["message"]);
      }
    );
  }

  leadFeedback(leadsId) {
    this.auth.startLoader();
    this.auth.getFeedbackOfLeads1(leadsId).subscribe((data) => {
      this.feedbackList = data;
      this.auth.stopLoader();
    });
  }

  onsubmitFilter(event) {
    this.page = 1;

    let formFilter = event.value;

    let projects: Array<any> = [];
    let assignedTo: Array<any> = [];
    let assignedBy: Array<any> = [];
    let status: Array<any> = [];
    let callStatus: Array<any> = [];

    if (formFilter.lAssignedTo.length > 0) {
      for (var str of formFilter.lAssignedTo) {
        assignedTo.push(str.id);
      }
    } else {
      assignedTo = [];
    }
    if (formFilter.lProject.length > 0) {
      for (var str of formFilter.lProject) {
        projects.push(str.name);
      }
    } else {
      projects = [];
    }
    if (formFilter.lcStatus.length > 0) {
      for (var str of formFilter.lcStatus) {
        callStatus.push(str.name);
      }
    } else {
      callStatus = [];
    }

    this.json_obj = {
      rFromDate: this.auth.toTimestamp(formFilter.rdFrom),
      rToDate: this.auth.toTimestamp(formFilter.rdTo),
      aFromDate: this.auth.toTimestamp(formFilter.adFrom),
      aToDate: this.auth.toTimestamp(formFilter.adTo),
      csFromDate: this.auth.toTimestamp(formFilter.csdFrom),
      csToDate: this.auth.toTimestamp(formFilter.csdTo),
      statusList: ["OPEN"],
      callStatusList: callStatus,
      assignedByIds: assignedBy,
      assignedToIds: assignedTo,
      projectsString: projects,
      sourceString: [],
      orderBy: formFilter.orderBy,
      sortBy: formFilter.sortBy,
    };

    this.getFilteredLeadsReportByPage(this.json_obj);
  }

  filterFieldsData(event: any) {
    this.auth.startLoader();
    let target = event.target;

    if (target.querySelector("#agent").value != null) {
      this.onchangeAgentString = target.querySelector("#agent").value;
    } else {
      this.onchangeAgentString = null;
    }

    if (this.ROLE) {
      if (target.querySelector("#source").value != null && this.ROLE) {
        this.onchangeSourceString = target.querySelector("#source").value;
      } else {
        this.onchangeSourceString = null;
      }
    }
    if (target.querySelector("#visitStatus").value != null) {
      this.onchangeVisitStatus = target.querySelector("#visitStatus").value;
    } else {
      this.onchangeVisitStatus = null;
    }
    if (target.querySelector("#callStatus").value != null) {
      this.onchangeCallStatusString = target.querySelector("#callStatus").value;
    } else {
      this.onchangeCallStatusString = null;
    }

    if (target.querySelector("#datefrom").value != null) {
      this.inputDateFromString = target.querySelector("#datefrom").value;
      var datePipe = new DatePipe("en-US");
      this.inputDateFromString = datePipe.transform(
        this.inputDateFromString,
        "dd-MM-yyyy"
      );
    } else {
      this.inputDateFromString = null;
    }
    if (target.querySelector("#dateto").value != null) {
      this.inputDateToString = target.querySelector("#dateto").value;
      var datePipe = new DatePipe("en-US");
      this.inputDateToString = datePipe.transform(
        this.inputDateToString,
        "dd-MM-yyyy"
      );
    } else {
      this.inputDateToString = null;
    }
    if (target.querySelector("#projectName").value != null) {
      this.inputProjectNameString = target.querySelector("#projectName").value;
    } else {
      this.inputProjectNameString = null;
    }
  }
  itemCount: any;
  getFilteredLeadsReportByPage(jsonObj) {
    this.auth.startLoader();
    this.auth.filterReport(jsonObj, this.page, 20).subscribe(
      (data) => {
        this.openLeads = data["content"];
        this.config.totalItems = data["totalElements"];
        this.itemCount = data["totalElements"];
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  feedbackStatus(str) {
    this.callStatusArray = [];
    this.leadsStatus = str;
    if (str === "open") {
      this.callStatusFieldCheck = true;
      this.callStatusBoolean = false;
      this.statusCheck = true;
      this.callStatusArray = this.acceptCallStatus;
      enableStatus();
    } else if (str === "decline") {
      this.callStatusFieldCheck = true;
      this.callStatusBoolean = false;
      this.statusCheck = false;
      this.callStatusArray = this.rejectCallStatus;
      enableStatus();
    } else if (str === "close") {
      this.callStatusFieldCheck = false;
      this.callStatusBoolean = true;
      this.statusCheck = false;
      this.callStatusArray = [];
      this.callStatusArray = this.rejectCallStatus;
      disableStatus();
    } else {
      this.callStatusArray = [];
      this.leadsStatus = null;
      this.callStatusBoolean = false;
      this.callStatusFieldCheck = true;
      disableStatus();
      alert("Please select status");
    }
  }

  leadStatusUpdate(obj, userObj) {
    this.lead = obj;
    this.feedbackUser = userObj;
    this.leadsStatus = "";
    this.callStatus = "";
    this.statusCheck = false;
    this.callStatusBoolean = false;
    this.callStatusFieldCheck = false;
  }

  feedbackCallStatus(str) {
    this.callStatus = str;
    if (this.leadsStatus === "open") {
      if (
        this.callStatus === "Visited" ||
        this.callStatus === "Virtual Meeting Done"
      ) {
        this.callStatusBoolean = true;
        this.statusCheck = true;
      } else {
        this.statusCheck = true;
        if (this.callStatus != "") {
          this.callStatusBoolean = true;
        } else {
          this.callStatusBoolean = false;
          this.callStatus = null;
          alert("Please select call status");
        }
      }
    } else {
      if (this.callStatus != "") {
        this.callStatusBoolean = true;
      } else {
        this.callStatusBoolean = false;
        this.callStatus = null;
        alert("Please select call status");
      }
    }
  }

  saveRemark(event) {
    var date: any;
    var remark: string = "";
    let target = event.target;
  
    var feedbackObj: any = {};
  
    // Log to check event target and remark area
    console.log("Event target:", target);
  
    if (target.querySelector("#remarkArea").value != null) {
      remark = target.querySelector("#remarkArea").value;
    } else {
      remark = "";
    }
  
    // Log the remark value
    console.log("Remark:", remark);
   feedbackObj["id"]=this.lead.id;
    feedbackObj["message"] = remark;
    feedbackObj["status"] = this.leadsStatus;
    feedbackObj["callStatus"] = this.callStatus;
    feedbackObj["user"] = this.feedbackUser;
  
    // Log the feedback object before date and status checks
    console.log("Feedback object initialized:", feedbackObj +"test"+feedbackObj["id"] );
  
    if (this.leadsStatus == "open") {
      if (target.querySelector("#chooseDate") != null) {
        date = target.querySelector("#chooseDate").value;
        date = this.auth.toDateAndTimeTimestamp(date);
      } else {
        alert("Remark and date can not be null");
        return;
      }
  
      // Log date value
      console.log("Date selected:", date);
  
      if (
        this.callStatus === "Visit Done" ||
        this.callStatus === "Visited" ||
        this.callStatus === "Virtual Meeting Done"
      ) {
        if (this.callStatus === "Visit Done") {
          feedbackObj["visitStatus"] = true;
          feedbackObj["callStatusDate"] = date;
        } else if (this.callStatus === "Virtual Meeting Done") {
          feedbackObj["virtualMeetingStatus"] = true;
          feedbackObj["callStatusDate"] = date;
        }
      } else {
        if (this.callStatus == "Visit Planned") {
          feedbackObj["callStatusDate"] = date;
        } else if (this.callStatus == "Callback") {
          feedbackObj["callStatusDate"] = date;
        } else if (this.callStatus == "Meeting Planned") {
          feedbackObj["callStatusDate"] = date;
        } else if (this.callStatus == "Not Picked") {
          feedbackObj["callStatusDate"] = date;
        } else if (this.callStatus == "Followup") {
          feedbackObj["callStatusDate"] = date;
        } else if (this.callStatus == "Virtual Meeting") {
          feedbackObj["callStatusDate"] = date;
        }
      }
    }
  
    // Log the final feedback object before making the API call
    console.log("Final feedback object:", feedbackObj);
  
    if (remark.trim().length > 0) {
      this.auth.startLoader();
      this.auth.saveLeadsFeedback(this.lead["id"], feedbackObj).subscribe(
        (data) => {
          // Log the success response data
          console.log("API Response:", data);
  
          this.getFilteredLeadsReportByPage(this.json_obj);
          this.auth.stopLoader();
          alert(data["output"]);
        },
        (err) => {
          // Log the error
          console.error("API Error:", err);
  
          this.auth.stopLoader();
          alert("Something wrong try again.");
        }
      );
    } else {
      alert("Remark should not be empty.");
    }
  }
  

  // saveRemark(event) {
  //   this.auth.startLoader();
  //   var date: string = "";
  //   var remark: string = "";
  //   let target = event.target;

  //   var feedbackObj: any = {}

  //   if (target.querySelector('#remarkArea').value != null) {
  //     remark = target.querySelector('#remarkArea').value;
  //   }
  //   else {
  //     remark = "";
  //   }

  //   feedbackObj['message'] = remark;
  //   feedbackObj['status'] = this.leadsStatus;
  //   feedbackObj['callStatus'] = this.callStatus
  //   feedbackObj['user'] = this.feedbackUser;

  //   if (this.leadsStatus == 'open') {
  //     if (this.callStatus != "Visited") {
  //       if (target.querySelector('#chooseDate').value != null) {
  //         date = target.querySelector('#chooseDate').value;
  //         var datePipe = new DatePipe("en-US");
  //         date = datePipe.transform(date, 'dd-MM-yyyy hh:mm a');
  //       }
  //       else {
  //         date = "";
  //       }

  //       if (this.callStatus == "Visit Planned") {
  //         feedbackObj['visitDate'] = date;
  //       } else if (this.callStatus == "Callback") {
  //         feedbackObj['callbackDate'] = date;
  //       } else if (this.callStatus == "Meeting Planned") {
  //         feedbackObj['meetingDate'] = date;
  //       } else if (this.callStatus == "Not Picked") {
  //         feedbackObj['callbackDate'] = date;
  //       } else if (this.callStatus == "Followup") {
  //         feedbackObj['followupDate'] = date;
  //       }
  //     } else {
  //       feedbackObj['visitStatus'] = true;
  //     }
  //   }

  //   if (remark.trim().length > 0) {
  //     this.auth.saveLeadsFeedback(this.lead['id'], feedbackObj).subscribe(
  //       data => {
  //         closeLeadFeedbackModel();
  //         this.auth.stopLoader();
  //         alert(data['output']);
  //       },
  //       err => {
  //         this.auth.stopLoader();
  //         alert("Something wrong try again.");
  //       }
  //     )
  //   } else {
  //     alert("Remark should not be empty.");
  //   }
  // }
}
