<div class="content-wrapper" style="background-color: #fff !important;">
    <section class="Leads_manage leads_redesign container2">
      <h3>Lead Performance Reviews</h3>
      <div class="filter-wrapper">
        <label>Start Date :</label>
        <input type="date" [(ngModel)]="startDate" [placeholder]="'Start Date: ' + startDate">
        <label>End Date:</label>
        <input type="date" [(ngModel)]="endDate" [placeholder]="'End Date: ' + endDate">
        <button (click)="applyfilter()">Filter</button>
    </div>
    
      <p style="font-size: 19px;font-weight: 600;">User Id: {{ leadsReport?.userId }}</p>
      <p style="font-size: 19px;font-weight: 600;">Name: {{ leadsReport?.userName }}</p>
      <div class="grid-container">
        <div class="card" id="blue">
          <div class="card-body">
              <div class="icon-circle" style="background-color: #8CC5FF;" >
                  <img src="../../../assets/icon/total-leads.png" alt="total-leads">
              </div>
              <h6>Total Leads </h6>
              <p>{{leadsReport?.totalLeads}}</p>
          </div>
      </div>
        <div class="card" id="green1">
          <div class="card-body">
              <div class="icon-circle" style="background-color: #2fa13b;">
                  <img src="../../../assets/icon/open-leads.png" alt="open-leads">
              </div>
              <h6>Open Leads </h6>
              <p> {{leadsReport?.openLeads}}</p>
          </div>
      </div>
      <div class="card " id="green2">
        <div class="card-body">
            <div class="icon-circle"  style="background-color: #2fa13b;">
                <img src="../../../assets/icon/visit-done.png" alt="visit-done">
            </div>
            <h6>Visit Done</h6>
            <p> {{leadsReport?.visitDone}}</p>
        </div>
    </div>
    <div class="card" id="green3">
      <div class="card-body">
          <div class="icon-circle" style="background-color: #2fa13b;" >
              <img src="../../../assets/icon/meeting-done.png" alt="meeting-done">
          </div>
          <h6>Metting Done </h6>
          <p>{{leadsReport?.meetingDone}}</p>
      </div>
  </div>
  <div class="card" id="red1">
    <div class="card-body">
        <div class="icon-circle" style="background-color: #e34234;">
            <img src="../../../assets/icon/success-leads.png" alt="success Leads">
        </div>
        <h6>Success Leads</h6>
        <p> {{leadsReport?.successLeads}}</p>
    </div>
</div>
<div class="card" id="orange1">
  <div class="card-body" (click)="handleDeclineLeadsClick('Open To Decline')" >
      <div class="icon-circle" style="background-color: #ff6534;" >
          <img src="../../../assets/icon/open-to-decine-leads.png" alt="open to decine leads" >
      </div>
      <h6>Open To Decline Leads </h6>
      <p>{{leadsReport?.openToDeclineLeads}}</p>
  </div>
</div>
<div class="card" id="red2">
  <div class="card-body" (click)="handleDeclineLeadsClick('Followup')">
      <div class="icon-circle"  style="background-color: #e34234;">
          <img src="../../../assets/icon/follow-up-to-decline.png" alt="follow-up-to-decline">
      </div>
      <h6>Follow Up To Decline </h6>
      <p> {{leadsReport?.followupToDecline}}</p>
  </div>
</div>
<div class="card" id="orange2">
  <div class="card-body" (click)="handleDeclineLeadsClick('Callback')">
      <div class="icon-circle" style="background-color: #ff6534;">
          <img src="../../../assets/icon/callback-to-decline.png" alt="callback-to-decline">
      </div>
      <h6>Call Back To Decline</h6>
      <p> {{leadsReport?.callbackToDecline}}</p>
  </div>
</div>
<div class="card" id="orange3">
  <div class="card-body" (click)="handleDeclineLeadsClick('Meeting Planned')">
      <div class="icon-circle" style="background-color: #ff6534;">
          <img src="../../../assets/icon/meeting plan to decline.png" alt="meeting-done-to-decline">
      </div>
      <h6>Meeting Plan To Decline </h6>
      <p>{{leadsReport?.meetingPlanToDecline}}</p>
  </div>
</div>
<div class="card" id="red3">
  <div class="card-body" (click)="handleDeclineLeadsClick('Visit Planned')">
      <div class="icon-circle" style="background-color: #e34234;">
          <img src="../../../assets/icon/visit-plan-to-decline.png" alt="visit-plan-to-decline">
      </div>
      <h6>Visit Plan To Decline </h6>
      <p>{{leadsReport?.visitPlanToDecline}}</p>
  </div>
</div>
<div class="card" id="orange4">
  <div class="card-body" (click)="handleDeclineLeadsClick('Meeting Done')">
      <div class="icon-circle orange" style="background-color: #ff6534;" >
          <img src="../../../assets/icon/meeting-done-to-decline.png" alt="meeting-done-to-decline">
      </div>
      <h6>Meeting Done To Decline </h6>
      <p> {{leadsReport?.meetingDoneToDecline}}</p>
  </div>
</div>
<div class="card" id="red4">
  <div class="card-body" (click)="handleDeclineLeadsClick('Visit Done')">
      <div class="icon-circle" style="background-color: #e34234;" >
          <img src="../../../assets/icon/visit-done-to-decline.png" alt="visit-done">
      </div>
      <h6>Visit Done To Decline </h6>
      <p>{{leadsReport?.visitDoneToDecline}}</p>
  </div>
</div>
<div class="card" id="red5">
  <div class="card-body" >
      <div class="icon-circle red" style="background-color: #e34234;">
          <img src="../../../assets/icon/decline-leads.png" alt="Decline Lead">
      </div>
      <h6>Decline Leads</h6>
      <p> {{leadsReport?.declineLeads}}</p>
  </div>
</div>
<div class="card" id="red6">
  <div class="card-body " >
      <div class="icon-circle" style="background-color: #e34234;">
          <img src="../../../assets/icon/not-called.png" alt="Not Called">
      </div>
      <h6>Not Called :</h6>
      <p> {{leadsReport?.notCalled}}</p>
  </div>
</div>  
      </div>
    </section>
  </div>
   