  <div class="content-wrapper leads_redesign" style="background-color: #fff !important;">

    <section class="Leads_manage leads_redesign">


      <!--Start filter form section -->

      <div class="bord">
        <div class="col-xs-12 col-sm-3 col-lg-12 ">
          <div class="form-group">
            <div class="date_filter_tab toggle__btn">
              <p class="" for="usr" value="assigned">Filter :</p>
              <button class="fib_system_btn">
                <i class=" fa fa-angle-down" aria-hidden="true"></i>
              </button>
            </div>
            <div class="collapse" id="collapsefilters">
              <form #filterForm="ngForm" (ngSubmit)="onsubmitFilter(filterForm)">
                <div class="row">
                  <!-- Agent/User Filter -->
                  <div class="col-xs-12 col-sm-12 col-lg-3">
                    <div class="form-group">
                      <label for="usr">Agent:</label>
                      <ng-multiselect-dropdown ngModel name="lAgent" #lAgent="ngModel" class="im_multi"
                        [placeholder]="'Agents Name'" [data]="userList" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>
              
                  <!-- Project Filter -->
                  <div class="col-xs-12 col-sm-12 col-lg-2">
                    <div class="form-group">
                      <label for="usr">Project Name:</label>
                      <ng-multiselect-dropdown ngModel name="lProject" #lProject="ngModel" class="im_multi"
                        [placeholder]="'Projects Name'" [data]="projectNameList" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  
                  <div class="col-xs-12 col-sm-12 col-lg-2">
                    <div class="form-group">
                      <label for="sel1">Total Item founds :</label>
                      <input class="form-control" type="text" value=" {{itemCount}}" disabled />
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2 d-flex ">    
                    <div class="form-group ">
                      <label> &nbsp;</label>
                      <button data-toggle="tooltip" data-placement="top" title="Filter" type="submit" class="fib_system_btn  mr-3">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div class="form-group" style="margin-top: 50px;">
                      <button _ngcontent-nji-c5="" class="fib_system_btn  mr-3" data-target="#multipleLeadsAssignModel" data-toggle="modal" type="button"><i _ngcontent-nji-c5="" aria-hidden="true" class="fa fa-user-plus"></i></button>
                    </div>
                  </div>
                </div>
              </form>
              
            </div>

          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-lg-4">
        <!-- Modal -->
        <div class="modal fade" id="multipleLeadsAssignModel" role="dialog" data-keyboard="false"
          data-backdrop="static">
          <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Select Employee</h4>
                <button type="button" class="close" data-dismiss="modal">
                  <i class="fa fa-window-close" aria-hidden="true"></i>
                </button>
              </div>

              <div class="modal-body">
                <form role="form" (submit)="assignMultipleLead()">
                  <div class="box-body">
                    <div class="form-group">
                      <label>Select Employee</label>
                      <select class="form-control" (change)="
                            onChangeUserDropBoxOfMultipleAssign(
                              $event.target.value
                            )
                          ">
                        <option *ngFor="let user of childUsers" value="{{ user.id }}">
                          {{ user.firstName + " " + user.lastName }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- /.box-body -->
                  <div class="box-footer">
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!--End filter form section -->

      <div class="col-xs-12">
        <div class="table-responsive leads_table_redesign table-fixed">
          <table summary="" class="table table-bordered borderStyle table-hover table-striped text-center">
            <thead>
              <tr>
                <th style="width: 200px">
                  <input class="form-check-input" type="checkbox" id="parent"
                    (change)="selectAll($event.target.checked)" />
                </th>
                
                <th>Sr. No.</th>
                <th>Leads Id</th>
                <th style="background-color: #ffd75f21">Name</th>
                <th>Email</th>
                <th style="background-color: #b17fad26">Mobile No</th>
                <!-- <th>Source</th> -->
                <th style="background-color: #0ce2e21f">Project Name</th>
                <th>Received Date</th>
                <!-- <th>Assigned Date</th>
                <th>Update Date</th> -->
                <th>Query</th>
                <!-- <th>Status</th> -->
                <th>Feedback</th>
                <th>Assign Project</th>
              </tr>
            </thead>
            <tbody class="serialNumber">
              <tr class="content" *ngFor="let leads of callLeads | paginate: config">
                <td>
                  <div>
                    <input type="checkbox" id="child_checkbox" (change)="onCheck(leads.id, $event)" />
                  </div>
                </td>
                <td></td>
                <td>{{ leads.id }}</td>
                <td style="background-color: #ffd75f21">{{ leads.name }}</td>
                <td>{{ leads.email }}</td>
                <td style="background-color: #b17fad26">{{ leads.phone }}</td>
                <td style="background-color: #0ce2e21f">{{ leads.projectName }}</td>
                <td *ngIf="leads.receivedOn == 0"></td>
                <td *ngIf="leads.receivedOn != 0">{{ leads.receivedOn | date: "dd-MM-yyyy hh:mm a" }}</td>

                <td style="width: 10%">{{ leads.queryInfo }}</td>
                
                <td *ngIf="!ROLE">
                  <button class="fib_system_btn_auto_w" value="Feedback" title="Feedback" data-toggle="modal"
                    (click)="leadStatusUpdate(leads, leads.user)" data-target="#feedbackModal">
                    <i class="fa fa-comments-o" aria-hidden="true"></i>
                  </button>
                </td>
                <td id="tt">
                  <select name="users" (change)="assignLead($event.target.value, leads.id)">
                    <option *ngIf="leads.user != null">
                      {{ leads.user.firstName + " " + leads.user.lastName }}
                    </option>
                    >
                    <option *ngIf="leads.user == null" value="0">
                      Select User
                    </option>
                    <option *ngFor="let user of childUsers" value="{{ user.id }}">
                      {{ user.firstName + " " + user.lastName }}
                    </option>
                  </select>
                </td>
                <!-- <td *ngIf="!ROLE">
                  {{ leads.user.firstName + " " + leads.user.lastName }}
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <!--end of .table-responsive-->
        <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
      </div>
    </section>
    <!-- partial -->
  </div>

  <div class="modal fade" id="feedbackModal" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_heading text-center">
          <h3 class="text-primary">Update Feedback</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-lg-12">
              <div class="form-group">
                <label for="usr">Status</label>
                <select name="users" class="form-control" (change)="feedbackStatus($event.target.value)"
                  style="width: 100%">
                  <option value="">Select Status</option>
                  <option value="Open">Open</option>
                  <option value="Decline">Decline</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-lg-12" *ngIf="statusCheck">
              <div class="form-group">
                <label for="usr">Call Status</label>
                <select id="myCallSelect" name="users" class="form-control"
                  (change)="feedbackCallStatus($event.target.value)" style="width: 100%">
                  <option value="">Select Call Status</option>
                  <option *ngFor="let str of callStatusArray" value="{{ str }}">
                    {{ str }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="callStatusBoolean">
            <div class="col-xs-12 col-sm-12 col-lg-12">
              <form name="feedbackForm" (submit)="saveRemark($event)">
                <div class="form-group" style="width: 100%">
                  <label for="">Remark</label>
                  <textarea placeholder="Remark please" class="form-control" id="remarkArea" name="remark"></textarea>
                </div>
                <div class="form-group" style="width: 100%" *ngIf="statusCheck">
                  <label for="">Choose Date</label>
                  <input type="datetime-local" class="form-control" id="chooseDate" placeholder="choose date" required />
                </div>
                
                <div class="form-group">
                  <button type="submit" class="btn btn-primary pull-right">
                    Save Feedback
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>