import { Component, OnInit, NgModule } from '@angular/core';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { HttpClientModule } from '@angular/common/http';
import { RouterTestingModule } from '@angular/router/testing';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Users } from 'src/app/model/users.model';

@Component({
  selector: 'app-decline-lead',
  templateUrl: './decline-lead.component.html',
  styleUrls: ['./decline-lead.component.css']
})

@NgModule({

  declarations: [
  ],
  imports: [
    NgxUiLoaderModule,
    HttpClientModule,
    RouterTestingModule
  ],
  providers: [
    DatePipe
  ]
})
export class DeclineLeadComponent implements OnInit {

  userList: Users[];
  openLeads: any[];
  feedbackList: any[];

  onchangeCallStatusString: any;
  onchangeAgentString: any;
  onchangeSourceString: any;
  onchangeVisitStatus: any;

  inputDateFromString: any;
  inputDateToString: any;
  inputProjectNameString: any;
  sourceString: any[];
  projectNameString: any[];

  public config = {
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0
  };
  private page: number = 1;
  // private pages: Array<number>;
  // private leadsReports: Array<any>;
  // private filterStr: any;
  // private exportStr: any;

  ROLE: boolean;
  userRole: string;

  lead: any = {};
  callStatus: Array<any> = [];
  status: Array<any> = ["DECLINE"];
  statusCheck: boolean = false;
  callStatusArray: string[];
  callStatusBoolean: boolean = false;
  feedbackUser: any = {};

  projectNameList: Array<any> = [];
  sourceNameList: Array<any> = [];
  selectedProjects: Array<any> = [];
  selectedSource: Array<any> = [];
  selectedEmp: Array<any> = [];
  dropdownSettings: IDropdownSettings = {};

  rFromString: any;
  rToString: any;
  aFromString: any;
  aToString: any;





  MOBILE: boolean;
  mobile: string = localStorage.getItem('mobile');

  private json_obj = {
    'rFromDate': null,
    'rToDate': null,
    'aFromDate': null,
    'aToDate': null,
    'statusList': this.status,
    'callStatusList': null,
    'assignedByIds': null,
    'assignedToIds': null,
    'projectsString': null,
    'transferred': 'NO',
    'sourceString': null,
    'orderBy': "DESC",
    "sortBy": "receivedOn"
  };
  activeUsers: any[];
  callStatusFieldCheck: boolean = false;
  isValid: boolean = false;

  declineCallStatus: any[] = [
    { 'id': 1, 'name': 'Invalid Number' },
    { 'id': 2, 'name': 'Not Interested' },
    { 'id': 3, 'name': 'Already Purchased' },
    { 'id': 4, 'name': 'Budget Issue' },
    { 'id': 5, 'name': 'Location Issue' },
    { 'id': 6, 'name': 'Loan Issue' },
    { 'id': 7, 'name': 'Followup Done, Not Responding' },
    { 'id': 8, 'name': 'Plan Postponed' },
    {'id':9 ,'name':'Blocked'},
  ];

  itemCount: any;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {

    this.callStatus = this.declineCallStatus;

    this.dropdownSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 1,
    };

    this.isValid = localStorage.getItem('isValid') == 'YES';
    this.getFilteredLeadsReportByPage(this.json_obj);
    this.getAgents();
    this.getSources();
    this.getProjects();

    this.auth.getSalesAndActiveUsers().subscribe(
      data => {
        this.activeUsers = data;
      }
    )

  }

  pageChanged(event) {
    this.auth.startLoader();
    this.page = event;
    this.config.currentPage = event;
    this.getFilteredLeadsReportByPage(this.json_obj);
  }

  leadFeedback(leadsId, userId) {
    this.auth.startLoader();
    this.auth.getFeedbackOfLeads1(leadsId).subscribe(
      data => {
        this.feedbackList = data;
        this.auth.stopLoader();
      }
    )
  }

  onsubmitFilter(event) {
    this.page = 1;

    let formFilter = event.value;

    let projects: Array<any> = [];
    let sources: Array<any> = [];
    let assignedTo: Array<any> = [];
    let assignedBy: Array<any> = [];
    let callStatus: Array<any> = [];

    for(var str of formFilter.lAssignedBy){
      assignedBy.push(str.id);
    }
    for(var str of formFilter.lAssignedTo){
      assignedTo.push(str.id);
    }
    for(var str of formFilter.lProject){
      projects.push(str.name);
    }
    for(var str of formFilter.lSource){
      sources.push(str.name);
    }
    for(var str of formFilter.lcStatus){
      callStatus.push(str.name);
    }

    this.json_obj = {
      rFromDate: this.auth.toTimestamp(formFilter.rdFrom),
      rToDate: this.auth.toTimestamp(formFilter.rdTo),
      aFromDate: this.auth.toTimestamp(formFilter.adFrom),
      aToDate: this.auth.toTimestamp(formFilter.adTo),
      statusList: this.status,
      callStatusList: callStatus,
      assignedByIds: assignedBy,
      assignedToIds: assignedTo,
      projectsString: projects,
      transferred: 'NO',
      sourceString: sources,
      orderBy: 'DESC',
      sortBy: 'receivedOn'
    };
    this.getFilteredLeadsReportByPage(this.json_obj)
  }

  getFilteredLeadsReportByPage(jsonObj) {
    this.auth.startLoader()
    this.auth.filterReport(jsonObj, this.page, 20).subscribe(
      data => {
        this.openLeads = data['content'];
        this.config.totalItems = data['totalElements'];
        this.itemCount = data["totalElements"];
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  getProjects() {
    this.auth.getProjects().subscribe(
      data => {
        var list: Array<any> = [];
        for (let project of data) {
          var obj: any = {};
          obj['id'] = project.id;
          obj['name'] = project.name;
          list.push(obj);
        }
        this.projectNameList = list;
      }
    )
  }

  getSources() {
    this.auth.getSourceString().subscribe(
      data => {
        var list: Array<any> = [];
        var id = 0;
        for (let source of data) {
          id = id + 1;
          var obj: any = {};
          obj['id'] = id;
          obj['name'] = source;
          list.push(obj);
        }
        this.sourceNameList = list;
      }
    )
  }

  getAgents() {
    this.auth.getUsers().subscribe(
      data => {
        var list: Array<any> = []
        for (let user of data) {
          var obj: any = {};
          obj['id'] = user.id;
          obj['name'] = user.firstName + " " + user.lastName;
          list.push(obj);
        }
        this.userList = list;
      }
    )
  }

}