import { Component, OnInit, NgModule } from "@angular/core";
import {
  HttpResponse,
  HttpClientModule,
  HttpClient,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { FormArray, FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { from } from "rxjs";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { RouterTestingModule } from "@angular/router/testing";
import { DatePipe } from "@angular/common";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Leads } from "src/app/model/leads.model";
import { Users } from "src/app/model/users.model";
import { AuthService } from "src/app/services/auth.service";

declare function resetDropdown(): any;
declare function enableStatus(): any;
declare function disableStatus(): any;
declare function closeModal(): any;

@Component({
  selector: "app-leads",
  templateUrl: "./leads.component.html",
  styleUrls: ["./leads.component.css"],
})
@NgModule({
  imports: [
    NgxUiLoaderModule,
    HttpClientModule,
    RouterTestingModule,
    HttpClient,
  ],
  providers: [DatePipe],
})
export class LeadsComponent implements OnInit {
  active = false;
  leadsArray: Leads[];
  users: Users[];
  activeUsers: Users[];
  selectedIds: Array<any> = [];
  selectedId: Array<any> = [];
  userId: any;
  count = 0;

  onchangeAgentString: any;
  onchangeSourceString: any;

  dropdownSettings: IDropdownSettings = {};

  inputDateFromString: any;
  inputDateToString: any;
  inputProjectNameString: any;

  sourceString: any[];
  projectNameString: any[];

  ROLE: boolean;
  userRole: string;

  lead: any = {};

  acceptCallStatus: string[] = new Array(
    "Followup",
    "Callback",
    "Not Connected",
    "Visit Planned",
    "Meeting Planned",
    "Virtual Meeting Planned",
    "Visit Done",
    "Meeting Done",
    "Virtual Meeting Done",
    "Followup Done, Not Responding",
  );

  rejectCallStatus: string[] = new Array(
    "Invalid Number",
    "Not Interested",
    "Already Purchased",
    "Budget Issue",
    "Location Issue",
    "Loan Issue",
    "Plan Postponed",
    "Blocked",
    "Not Responding",
    "Broker"
  );
  leadsStatus: string = "";
  callStatus: string = "";
  statusCheck: boolean = false;
  callStatusArray: string[];

  private json_arr = {
    startDate: null,
    endDate: null,
    projectName: null,
  };

  callStatusBoolean: boolean = false;
  feedbackUser: any = {};

  MOBILE: boolean;
  mobile: string = localStorage.getItem("mobile");
  isValid: boolean = false;
  projectNameList: any[];
  sourceNameList: any[];
  selectedProjects: any;
  selectedSource: any;

  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      idField: "id",
      textField: "name",
      itemsShowLimit: 1,
    };

    this.getProjects();

    this.getSources();

    this.userRole = localStorage.getItem("role");
    if (this.userRole === "ADMIN") {
      this.ROLE = true;
    } else {
      this.ROLE = false;
    }

    this.isValid = localStorage.getItem("isValid") == "YES";

    if (this.mobile === "8076042671") {
      this.MOBILE = true;
    } else {
      this.MOBILE = false;
    }

    this.auth.getUsers().subscribe((data) => {
      this.users = data;
    });

    this.auth.getSalesAndActiveUsers().subscribe((data) => {
      this.activeUsers = data;
    });

    this.auth.filterLeads(this.json_arr).subscribe((data) => {
      this.leadsArray = data;
    });

    this.auth.getSourceString().subscribe((data) => {
      this.sourceString = data;
    });

    this.auth.getProjectNameString().subscribe((data) => {
      this.projectNameString = data;
    });
  }

  activeUser(userList: any[]) {
    for (let user of userList) {
      if (user.deleted) {
        let index = userList.findIndex((x) => x.id === user.id);
        userList.splice(index, 1);
      }
    }
    return userList;
  }

  leadsBySource(sourceString) {
    if (sourceString == "all") {
      this.auth.getLeads().subscribe((data) => {
        this.leadsArray = data;
        this.router.navigate["leads"];
      });
    } else {
      this.auth.getLeadsBySource(sourceString).subscribe((data) => {
        this.leadsArray = data;
        this.router.navigate["leads"];
      });
    }
  }

  onChange(userId, leadsId) {
    this.selectedId = [];
    var arrObj = {};
    arrObj["leadsId"] = leadsId;
    this.selectedId.push(arrObj);
    if (userId != 0) {
      this.auth.assignLeads(userId, this.selectedId).subscribe((data) => {
        alert(data["message"]);
      });
    }
  }
  onChangeUserDropBoxOfMultipleAssign(user_id) {
    this.userId = user_id;
  }

  onCheck(leadId, event) {
    var arrObj = {};
    arrObj["leadsId"] = leadId;
    if (event.target.checked) {
      this.selectedIds.push(arrObj);
    } else {
      let index = this.selectedIds.findIndex((x) => x["leadsId"] === leadId);
      this.selectedIds.splice(index, 1);
    }
  }

  assignMultipleLead() {
    this.auth.assignLeads(this.userId, this.selectedIds).subscribe(
      (data) => {
        alert(data["message"]);
      },
      (error) => {
        alert(error);
      }
    );
  }

  assignLead(userId, leadId) {
    let leadsArray = [{ leadsId: leadId }];

    this.auth.assignLeads(userId, leadsArray).subscribe(
      (data) => {
        alert(data["message"]);
      },
      (error) => {
        alert(error);
      }
    );
  }
  onsubmitFilter(event) {
    let formFilter = event.value;

    let projectstr: any;

    if (formFilter.lProject.length > 0) {
      for (var str of formFilter.lProject) {
        projectstr = str.name;
      }
    } else {
      projectstr = null;
    }

    this.json_arr = {
      startDate: this.auth.toTimestamp(formFilter.rdFrom),
      endDate: this.auth.toTimestamp(formFilter.rdTo),
      projectName: projectstr,
    };

    console.log(this.json_arr);

    this.auth.filterLeads(this.json_arr).subscribe((data) => {
      this.leadsArray = data;
    });
  }

  feedbackStatus(str) {
    this.leadsStatus = str;
    if (str === "open") {
      this.callStatusBoolean = false;
      this.statusCheck = true;
      this.callStatusArray = this.acceptCallStatus;
      enableStatus();
    } else if (str === "decline") {
      this.callStatusBoolean = false;
      this.statusCheck = false;
      this.callStatusArray = this.rejectCallStatus;
      enableStatus();
    } else {
      this.callStatusArray = [];
      this.leadsStatus = null;
      this.callStatusBoolean = false;
      disableStatus();
      alert("Please select status");
    }
  }

  leadStatusUpdate(obj, userObj) {
    this.lead = obj;
    this.feedbackUser = userObj;
    this.callStatusArray = [];
    this.leadsStatus = null;
    this.callStatus = "";
    this.statusCheck = false;
    this.callStatusBoolean = false;
    resetDropdown();
    disableStatus();
  }

  feedbackCallStatus(str) {
    this.callStatus = str;
    if (this.callStatus != "") {
      if (this.leadsStatus === "open") {
        this.callStatusBoolean = true;
        this.statusCheck = true;
      } else {
        this.callStatusBoolean = true;
        this.statusCheck = false;
      }
    } else {
      this.callStatusBoolean = false;
      this.callStatus = null;
      alert("Please select call status");
    }
  }

  saveRemark(event) {
    var date: any;
    var remark: string = "";
    let target = event.target;

    var feedbackObj: any = {};

    if (target.querySelector("#remarkArea").value != null) {
      remark = target.querySelector("#remarkArea").value;
    }

      if (target.querySelector("#chooseDate") != null) {
        date = target.querySelector("#chooseDate").value;
        if (date.trim().length > 0) {
          date = this.auth.toDateAndTimeTimestamp(date);
        }
      }


    if(this.callStatus == 'OPEN'){
      if (remark.length <= 0 && date <= 0) {
        alert("Remark and date should not be empty.");
        return;
      }
    }else{
      if (remark.length <= 0) {
        alert("Remark should not be empty.");
        return;
      }
    }

      feedbackObj["message"] = remark;
      feedbackObj["status"] = this.leadsStatus;
      feedbackObj["callStatus"] = this.callStatus;
      feedbackObj["user"] = this.feedbackUser;

      if (this.leadsStatus == "open") {
        if (
          this.callStatus === "Visit Done" ||
          this.callStatus === "Virtual Meeting Done" ||
          this.callStatus === "Meeting Done"
        ) {
          if (this.callStatus === "Visit Done") {
            feedbackObj["visitStatus"] = true;
            feedbackObj["visitDoneDate"] = date;
          } else if (this.callStatus === "Virtual Meeting Done") {
            feedbackObj["virtualMeetingStatus"] = true;
            feedbackObj["virtualMeetingDoneDate"] = date;
          } else if (this.callStatus === "Meeting Done") {
            feedbackObj["meetingStatus"] = true;
            feedbackObj["meetingDoneDate"] = date;
          }
        }
        feedbackObj["callStatusDate"] = this.auth.toTimestamp(date);
      }
      this.auth.saveLeadsFeedback(this.lead["id"], feedbackObj).subscribe(
        (data) => {
          this.auth.filterLeads(this.json_arr).subscribe((data) => {
            this.leadsArray = data;
          });
          alert(data["output"]);
        },
        (err) => {
          console.log(err);
          alert("Something wrong try again.");
        }
      );
  }

  //Multi select dropdown option

  onProjectSelect(item: any) {
    var projObj = {};
    projObj["id"] = item.id;
    projObj["name"] = item.name;
    this.selectedProjects.push(projObj);
  }

  onProjectDeSelect(item: any) {
    let index = this.selectedProjects.findIndex((x) => x["id"] === item.id);
    this.selectedProjects.splice(index, 1);
  }

  onSelectAllProject(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj["id"] = item.id;
      projObj["name"] = item.name;
      this.selectedProjects.push(projObj);
    }
  }

  onDeSelectAllProject(items: any) {
    this.selectedProjects = [];
  }

  //Source

  onSourceSelect(item: any) {
    var projObj = {};
    projObj["id"] = item.id;
    projObj["name"] = item.name;
    this.selectedSource.push(projObj);
  }

  onSourceDeSelect(item: any) {
    let index = this.selectedProjects.findIndex((x) => x["id"] === item.id);
    this.selectedSource.splice(index, 1);
  }

  onSelectAllSource(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj["id"] = item.id;
      projObj["name"] = item.name;
      this.selectedSource.push(projObj);
    }
  }

  onDeSelectAllSource(items: any) {
    this.selectedSource = [];
  }

  //Multi select dropdown option get data

  getProjects() {
    this.auth.getProjects().subscribe((data) => {
      var list: Array<any> = [];
      for (let project of data) {
        var obj: any = {};
        obj["id"] = project.id;
        obj["name"] = project.name;
        list.push(obj);
      }
      this.projectNameList = list;
    });
  }

  getSources() {
    this.auth.getSourceString().subscribe((data) => {
      var list: Array<any> = [];
      var id = 0;
      for (let source of data) {
        id = id + 1;
        var obj: any = {};
        obj["id"] = id;
        obj["name"] = source;
        list.push(obj);
      }
      this.sourceNameList = list;
    });
  }
}
