import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-show-crm-child-review',
  templateUrl: './show-crm-child-review.component.html',
  styleUrls: ['./show-crm-child-review.component.css']
})
export class ShowCrmChildReviewComponent implements OnInit {
  data: any;
  user_id:any;
  // id = localStorage.getItem('id');
  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.auth.getUsers().subscribe((res) => {
      this.data = res;

    });
  }
  // onSubmit(){
  //   if (this.user_id) {
  //     this.router.navigate(['admin/review-pending'], {
  //       queryParams: { id: this.user_id }
  //     });
  //   }
  // }
  onUpdate(){
    if (this.user_id) {
      this.router.navigate(['crm/performance'], {
        queryParams: { id: this.user_id }
      });
    }
  }
}
