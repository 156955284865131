import { Component, OnInit, NgModule } from "@angular/core";
import {
  HttpResponse,
  HttpClientModule,
  HttpClient,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { FormArray, FormGroup, FormBuilder, FormControl } from "@angular/forms";
import { from } from "rxjs";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { RouterTestingModule } from "@angular/router/testing";
import { DatePipe } from "@angular/common";
import { AddLeadComponent } from "../add-lead/add-lead.component";
import { AuthService } from "src/app/services/auth.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})

@NgModule({
  imports: [
    NgxUiLoaderModule,
    HttpClientModule,
    RouterTestingModule,
    HttpClient,
  ],
  providers: [DatePipe],
})
export class ChartComponent implements OnInit {

  onchangeAgentString: any;
  inputDateFromString: any;
  inputDateToString: any;

  meetingsData: any[];
  users: any[];
  activeUsers: any[];

  private pageSize: number = 20;
  // public config = {
  //   itemsPerPage: 20,
  //   currentPage: 0,
  //   totalItems: 0
  // };
  private page: number = 1;
  userList: any[];
  dropdownSettings: IDropdownSettings = {};


  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      idField: "id",
      textField: "name",
      itemsShowLimit: 1,
    };
    this.getAgents();

    this.getMeetingDetailsByPage();
  }

  getAgentTodayMeeting() {
    this.auth.getAgentTodayMeeting().subscribe(
      (data) => {
        this.auth.stopLoader();
        this.meetingsData = data;
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  getMeetingDetailsByPage() {
    this.auth.startLoader();
    this.auth.getMeetingDetailsByManager(localStorage.getItem('id')).subscribe(
      (data) => {
        this.auth.stopLoader();
        this.meetingsData = data;
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  // activeUser(userList: any[]) {
  //   for (let user of userList) {
  //     if (user.deleted) {
  //       let index = userList.findIndex((x) => x.id === user.id);
  //       userList.splice(index, 1);
  //     }
  //   }
  //   return userList;
  // }

  onsubmitFilter(event) {
    this.auth.startLoader();

    let form = event.value;
    if(form.emp.length > 0){
      this.onchangeAgentString = form.emp[0].id;
    }else{
      this.onchangeAgentString = 0;
    }
    // for (var str of form.emp) {
    //   this.onchangeAgentString = str.id;
    // }
    this.inputDateFromString = this.auth.toTimestamp(form.dFrom);

    console.log(this.inputDateFromString)

    if (this.inputDateFromString != null || this.onchangeAgentString != null) {
      var json_arr = {
        startDate: this.inputDateFromString,
        userId: this.onchangeAgentString,
      };

      this.auth.meetingFilter(json_arr).subscribe((data) => {
        this.auth.stopLoader();
        this.meetingsData = data;
      },
      err => {
        this.auth.stopLoader();
        console.log(err);
      });
    } else {
      alert("Please select date or agent name!");
    }
  }

  getAgents() {
    this.auth.getUsers().subscribe((data) => {
      var list: Array<any> = [];
      for (let user of data) {
        var obj: any = {};
        obj["id"] = user.id;
        obj["name"] = user.firstName + " " + user.lastName;
        list.push(obj);
      }
      this.userList = list;
    });
  }
}

