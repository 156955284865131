import { Component, OnInit, NgModule, Pipe } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { RouterTestingModule } from "@angular/router/testing";
import { DatePipe } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { AuthService } from "src/app/services/auth.service";
import { ActivatedRoute } from "@angular/router";
declare function closeLeadsAssignModel(): any;
declare function getRadioValue(): any;
@Component({
  selector: "app-show-all-leads-responce",
  templateUrl: "./show-all-leads-responce.component.html",
  styleUrls: ["./show-all-leads-responce.component.css"],
})
@NgModule({
  declarations: [],
  imports: [HttpClientModule, RouterTestingModule, HttpClient],
  providers: [DatePipe],
})
@Pipe({ name: "safeResourceUrl" })
export class ShowAllLeadsResponceComponent implements OnInit {
  userId: any;
  leadsReport: any[];
  leadsReport1: any[];
  feedbackList: any[];
  selectedId: Array<any> = [];
  selectedIds: Array<any> = [];
  numberOfLeads: number;
  leadsReports: any;
  activeUsers: any[];
  projectNameList: Array<any> = [];
  sourceNameList: Array<any> = [];
  userList: Array<any> = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings1: IDropdownSettings = {};
  inp: any;
  isValid: boolean = false;
  callStatus: Array<any> = [];
  openString: any;
  declineString: any;
  closeString: any;
  selectedLeadStatus: Array<any> = [];
  selectedCallStatus: Array<any> = [];
  itemCount: any;
  expanded = false;
  lastcallstatus: Array<any> = [];
  selectedlastcallstatus: Array<any> = [];
  selectedFilter: any;
  filteredLeadsReport: any[];
  status: any;
  pagedLeadsReport: any[];
  private pageSize: number = 20;
  config = {
    currentPage: 0,
    itemsPerPage: 20,
    totalItems: 0,
  };
  startDate: any; 
  endDate: any;
  startDateObj:any;
  endDateObj:any;
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  public transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  ngOnInit() {
    this.userId = this.route.snapshot.queryParamMap.get("id");
    this.status=this.route.snapshot.queryParamMap.get("status");
    this.startDateObj = this.route.snapshot.queryParamMap.get("fromDate");
    this.endDateObj =this.route.snapshot.queryParamMap.get("toDate");
    this.loadData(this.config.currentPage, this.config.itemsPerPage);
    console.log("this.config.currentPage" +this.config.currentPage);
    console.log("this.config.itemsPerPage"+ this.config.itemsPerPage);
    
    this.loadPerformanceData();
  }

  pageChanged(event) {
    console.log("Page changed to:", event);
    if (this.config.currentPage < event) {
      console.log("Clicked on Next" + this.config.currentPage);
    } else if (this.config.currentPage > event) {
      console.log("Clicked on Previous"+ this.config.currentPage);
    }
    this.config.currentPage = event;
    this.loadData(this.config.currentPage-1, this.config.itemsPerPage);
  }
  

  loadData(page, size) {
    if (this.status === "Open To Decline") {
        if (!this.startDateObj && !this.endDateObj) {
            this.auth.opendeclinedata(this.userId, page, size).subscribe((data) => {
                this.handleResponse(data);
            });
        } else {
            this.auth.openToDeclineWithDate(this.userId, this.startDateObj, page, size, this.endDateObj).subscribe((data) => {
                this.handleResponse(data);
            });
        }
    } else {
        if (this.status && this.startDateObj && this.endDateObj) {
            this.auth.totalopendeclinedata(this.userId, this.status, this.startDateObj, page, size,this.endDateObj).subscribe((data) => {
                this.handleResponse(data);
            });
        } else if (this.status) {
            this.auth.AlldeclineLead(this.userId, this.status, page, size).subscribe((data) => {
                this.handleResponse(data);
            });
        } else {
            console.log("wrong api")
        }
    }
}

handleResponse(data) {
    console.log("Response data:", data.content);
    console.log("start", this.startDateObj);
    console.log("end", this.endDateObj);
    console.log("status"+ this.status);
    this.leadsReport = data["content"];
    this.config.totalItems = data["totalElements"];
    this.itemCount = data["totalElements"];
    this.config.itemsPerPage = this.pageSize;
    this.numberOfLeads = this.leadsReport.length;
    this.auth.stopLoader();
}
  loadPerformanceData() {
    this.auth.performance(this.userId).subscribe(
      (data) => {
        this.leadsReports = data;
      },
      (error) => {
        console.error("Error loading performance data:", error);
      }
    );
  }

  leadFeedback(leadsId) {
    this.auth.startLoader();
    this.auth.getFeedbackOfLeads1(leadsId).subscribe(
      (data) => {
        this.feedbackList = data;
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }
  onChangeSize(selectedPageSize: string) {
    if (selectedPageSize) {
      this.pageSize = parseInt(selectedPageSize); 
      this.loadData(this.config.currentPage, this.pageSize); 
    } else {
      this.pageSize = 20; 
      this.loadData(this.config.currentPage, this.pageSize); 
    }
  }
  

  filterOpenToDecline(page,size) {
    console.log("leadsReport", this.status); 
    console.log("userID", this.userId);
    
    // Convert userId to number
    // const userIdNumber = parseInt(this.userId);

    // Convert startDate and endDate to Date objects
    const startDateObj = new Date(this.startDate).getTime();
    const endDateObj = new Date(this.endDate).getTime();

    this.auth
      .totalopendeclinedata(this.userId, this.status,startDateObj, endDateObj,page,size)
      .subscribe((data) => {
            this.leadsReport = data.content;
            console.log("stastus", this.status); 
            this.config.totalItems = data["totalElements"];
            this.config.itemsPerPage = this.pageSize;
        const numberOfLeads = this.leadsReport.length;
        this.numberOfLeads = numberOfLeads;
      });
}


  onChange(userId, leadsId) {
    this.auth.startLoader();
    this.selectedId = [];
    var arrObj = {};
    arrObj["leadsId"] = leadsId;
    this.selectedId.push(arrObj);
    if (userId != 0) {
      this.auth.assignLeads(userId, this.selectedId).subscribe(
        (data) => {
          this.auth.stopLoader();
          alert(data["message"]);
        },
        (error) => {
          this.auth.stopLoader();
        }
      );
    }
  }

  onCheck(leadId, event) {
    var arrObj = {};
    arrObj["leadsId"] = leadId;
    if (event.target.checked) {
      this.selectedIds.push(arrObj);
    } else {
      this.inp.checked = false;
      let index = this.selectedIds.findIndex((x) => x["leadsId"] === leadId);
      this.selectedIds.splice(index, 1);
    }
  }

  selectAll(isChecked: boolean) {
    var input = document.getElementsByTagName("input");
    if (isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === false
        ) {
          input[i].checked = true;
        }
      }
      for (var i = 0; i < this.leadsReport.length; i++) {
        var obj = {};
        obj["leadsId"] = this.leadsReport[i].leads.id;
        this.selectedIds.push(obj);
      }
    }
    if (!isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        var obj = {};
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === true
        ) {
          input[i].checked = false;
        }
      }
    }
  }

  onChangeUserDropBoxOfMultipleAssign(user_id) {
    this.userId = user_id;
  }

  assignMultipleLead(event) {
    if (this.selectedIds.length <= 0) {
      alert("Select lead(s) please");
      closeLeadsAssignModel();
      return;
    }
    if (this.userId <= 0) {
      alert("Select sales member");
      return;
    }
    var val = getRadioValue();
    if (val == "fresh") {
      this.auth.startLoader();
      this.auth.assignLeads(this.userId, this.selectedIds).subscribe(
        (data) => {
          this.auth.stopLoader();
          this.inp.checked = false;
          this.selectAll(false);
          alert(data["message"]);
          closeLeadsAssignModel();
        },
        (error) => {
          this.auth.stopLoader();
          alert(error);
        }
      );
    } else {
      this.auth.startLoader();
      this.auth
        .assignLeadsAsCallingData(this.userId, this.selectedIds)
        .subscribe(
          (data) => {
            this.auth.stopLoader();
            this.inp.checked = false;
            this.selectAll(false);
            alert(data["message"]);
            closeLeadsAssignModel();
          },
          (error) => {
            this.auth.stopLoader();
            alert(error);
          }
        );
    }
  }
}
