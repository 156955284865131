<div class="content-wrapper" style="background-color: #fff !important;">
    <section class="Leads_manage leads_redesign">
  
  
      <!--Start filter form section -->
  
      <div class="bord">
        <div class="col-xs-12 col-sm-3 col-lg-12 ">
          <div class="form-group">
            <div class="date_filter_tab toggle__btn">
              <p class="" for="usr" value="assigned">Filter :</p>
              <button class="  fib_system_btn">
                <i class="fa fa-angle-down" aria-hidden="true"></i>
              </button>
            </div>
            <div class="collapse" id="collapsefilters">
              <form #filterForm="ngForm" (submit)="onsubmitFilter(filterForm)">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-lg-3">
                      <div class="form-group">
                        <label for="usr">Date From:</label>
                        <input ngModel name="dFrom" #dFrom="ngModel" type="date" class="form-control" />
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-lg-3">
                      <div class="form-group">
                        <label for="usr">Employee</label>
                        <ng-multiselect-dropdown ngModel name="emp" #emp="ngModel" class="im_multi"
                          [placeholder]="'Leads Assigned To'" [data]="userList" [settings]="dropdownSettings">
                        </ng-multiselect-dropdown>
                      </div>
                    </div>
  
                    <div class="col-xs-12 col-sm-12 col-lg-2 d-flex ">
                      <div class="form-group ">
                        <label> &nbsp;</label>
                        <button type="submit" class="fib_system_btn  mr-3">
                          <i class="fa fa-filter" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  
      <!--End filter form section -->
    </section>
    <!-- partial:index.partial.html -->
    <section class="Leads_manage leads_redesign">
      <div class="col-xs-12">
        <div class="table-responsive table-fixed leads_table_redesign table-bg">
          <table summary="" class="table table-bordered table-hover  table-striped text-center borderStyle">
            <thead class="borderStyle">
              <tr>
                <th></th>
                <th>SN</th>
                <th>Meeting Id</th>
                <th style="background-color: #ffd75f21">Client Name</th>
                <th>Meeting Status</th>
                <th>Meeting's In Time</th>
                <th>Meeting's Out Time</th>
                <th>Agent Name</th>
              </tr>
            </thead>
            <tbody class="serialNumber">
              <tr class="content" *ngFor="let meeting of meetingsData">
                <th></th>
                <td></td>
                <td>{{ meeting.id }}</td>
                <td style="background-color: #ffd75f21">
                  {{ meeting.leads.name }}
                </td>
                <td>
                  {{
                  meeting.meetingInTime > 0
                  ? (
                  meeting.meetingOutTime > 0
                  ? "Meeting Done"
                  : "Meeting started"
                  )
                  : "Meeting not started yet"
                  }}
                </td>
                <td>
                  {{
                  meeting.meetingInTime > 0
                  ? (meeting.meetingInTime | date: "dd-MM-yyyy hh:mm a")
                  : ""
                  }}
                </td>
                <td>
                  {{
                  meeting.meetingOutTime > 0
                  ? (meeting.meetingOutTime | date: "dd-MM-yyyy hh:mm a")
                  : ""
                  }}
                </td>
                <td>
                  {{ meeting.user.firstName + " " + meeting.user.lastName }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end of .table-responsive-->
      </div>
    </section>
    <!-- partial -->
  </div>