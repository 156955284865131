import { Component, OnInit, NgModule, Pipe } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { RouterTestingModule } from "@angular/router/testing";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Users } from "src/app/model/users.model";
import { AuthService } from "src/app/services/auth.service";
import { ifError } from "assert";

declare function closeLeadsAssignModel(): any;
declare function getRadioValue(): any;

@Component({
  selector: "app-lead-report",
  templateUrl: "./lead-report.component.html",
  styleUrls: ["./lead-report.component.css"],
})
@NgModule({
  declarations: [],
  imports: [HttpClientModule, RouterTestingModule, HttpClient],
  providers: [DatePipe],
})
@Pipe({ name: "safeResourceUrl" })
export class LeadReportComponent implements OnInit {
  leadsReport: any[];
  leadsReport1: any[];
  // exportLeadsReport: any[];
  // users: Users[];
  feedbackList: any[];

  selectedId: Array<any> = [];
  selectedIds: Array<any> = [];

  activeUsers: any[];
  private pageSize: number = 20;
  public config = {
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0,
  };

  recordings: any[] = null;
  fileUrl: any;

  leadsStatus: any[] = [
    { id: 1, name: "Open" },
    { id: 2, name: "Decline" },
    { id: 3, name: "Close" },
    { id: 4, name: "Not Called" },
  ];

  openCallStatus: any[] = [
    { id: 7, name: "Followup" },
    { id: 2, name: "Callback" },
    // { id: 3, name: "Not Picked" },
    { id: 3, name: "Not Connected" },
    { id: 4, name: "Visit Planned" },
    { id: 5, name: "Meeting Planned" },
    { id: 6, name: "Virtual Meeting Planned" },
    { id: 1, name: "Visit Done" },
    { id: 8, name: "Meeting Done" },
    { id: 9, name: "Virtual Meeting Done" },
    { id: 18, name : "Resale" },
    { id: 19, name: "Followup Done, Not Responding" },
  ];

  filterStatus: any[] = [
    { id: "callStatusDate", name: "Call Status Date" },
    { id: "visitDoneDate", name: "Visit Done" },
    { id: "meetingDoneDate", name: "Meeting Done" },
    { id: "virtualMeetingDoneDate", name: "Virtual Meeting Done" },
    { id: "updateDate", name: "Last Updated Date" },

  ];

  declineCallStatus: any[] = [
    { id: 10, name: "Invalid Number" },
    { id: 11, name: "Not Interested" },
    { id: 12, name: "Already Purchased" },
    { id: 13, name: "Budget Issue" },
    { id: 14, name: "Location Issue" },
    { id: 15, name: "Loan Issue" },
    { id: 17, name: "Plan Postponed" },
    { id: 16, name: "Blocked" },
    { id: 20, name: "Not Responding" },

  ];

  //last call status
  // openlastcallstatus: any[] = [
  //   { id: 1, name: "Followup" },
  //   { id: 2, name: "Callback" },
  //   { id: 3, name: "Not Connected" },
  //   { id: 4, name: "Visit Planned" },
  //   { id: 5, name: "Meeting Planned" },
  //   { id: 6, name: "Virtual Meeting Planned" },
  //   { id: 7, name: "Visit Done" },
  //   { id: 8, name: "Meeting Done" },
  //   { id: 9, name: "Virtual Meeting Done" },
  // ]

  // declinelastcallstatus: any[] = [
  //   { id: 10, name: "Invalid Number" },
  //   { id: 11, name: "Not Interested" },
  //   { id: 12, name: "Already Purchased" },
  //   { id: 13, name: "Budget Issue" },
  //   { id: 14, name: "Location  Issue" },
  //   { id: 15, name: "Loan Issue" },
  //   { id: 16, name: "Folllowup Done, Not Responding" },
  //   { id: 17, name: "Plan Postponed" },
  //   { id: 18, name: "Abhi" },
  // ]

  private page: number = 1;
  private json_obj = {
    reFromDate: 0,
    reToDate: 0,
    asFromDate: 0,
    asToDate: 0,
    csFromDate: 0,
    csToDate: 0,
    statusList: null,
    callStatusList: null,
    lastcallstatusList : null,
    typeOfDate: null,
    assignedByIds: null,
    assignedToIds: null,
    projectsString: null,
    transferred: null,
    sourceString: null,
    orderBy: "DESC",
    sortBy: "receivedOn",
  };

  projectNameList: Array<any> = [];
  sourceNameList: Array<any> = [];
  userList: Array<any> = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings1: IDropdownSettings = {};
  inp: any;
  userId: number = 0;
  isValid: boolean = false;
  callStatus: Array<any> = [];
  openString: any;
  declineString: any;
  closeString: any;
  selectedLeadStatus: Array<any> = [];
  selectedCallStatus: Array<any> = [];
  // orderBy: any;
  // sortBy: any;
  itemCount: any;
  expanded = false;
  lastcallstatus: Array<any> = [];
  selectedlastcallstatus : Array<any> = [];

  constructor(
    private auth: AuthService,
    // private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  public transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {
    this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    // this.lastcallstatus = this.openlastcallstatus.concat(this.declinelastcallstatus);

    this.inp = document.getElementById("parent");
    this.isValid = localStorage.getItem("isValid") == "YES";

    this.dropdownSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      idField: "id",
      textField: "name",
      itemsShowLimit: 1,
    };

    this.dropdownSettings1 = {
      singleSelection: true,
      allowSearchFilter: true, 
      idField: "id",
      textField: "name",
      itemsShowLimit: 1,
    };

    this.getProjects();

    this.getAgents();

    this.getSources();

    this.auth.startLoader();

    this.getFilteredLeadsReportByPage(this.json_obj);

    this.auth.getSalesAndActiveUsers().subscribe((data) => {
      this.activeUsers = data;
    });
  }

  getRecordingOfLeads(leadsId, userId) {
    this.recordings = new Array();
    this.auth.callRecordOfLeads(leadsId, userId).subscribe((data) => {
      this.recordings = data;
    });
  }

  onCheck(leadId, event) {
    var arrObj = {};
    arrObj["leadsId"] = leadId;
    if (event.target.checked) {
      this.selectedIds.push(arrObj);
    } else {
      this.inp.checked = false;
      let index = this.selectedIds.findIndex((x) => x["leadsId"] === leadId);
      this.selectedIds.splice(index, 1);
    }
  }

  selectAll(isChecked: boolean) {
    var input = document.getElementsByTagName("input");
    if (isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === false
        ) {
          input[i].checked = true;
        }
      }
      for (var i = 0; i < this.leadsReport.length; i++) {
        var obj = {};
        obj["leadsId"] = this.leadsReport[i].leads.id;
        this.selectedIds.push(obj);
      }
    }
    if (!isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        var obj = {};
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === true
        ) {
          input[i].checked = false;
        }
      }
    }
  }

  onChangeUserDropBoxOfMultipleAssign(user_id) {
    this.userId = user_id;
  }

  assignMultipleLead(event) {
    if (this.selectedIds.length <= 0) {
      alert("Select lead(s) please");
      closeLeadsAssignModel();
      return;
    }
    if (this.userId <= 0) {
      alert("Select sales member");
      return;
    }
    var val = getRadioValue();
    if (val == "fresh") {
      this.auth.startLoader();
      this.auth.assignLeads(this.userId, this.selectedIds).subscribe(
        (data) => {
          this.auth.stopLoader();
          this.inp.checked = false;
          this.selectAll(false);
          alert(data["message"]);
          closeLeadsAssignModel();
        },
        (error) => {
          this.auth.stopLoader();
          alert(error);
        }
      );
    } else {
      this.auth.startLoader();
      this.auth
        .assignLeadsAsCallingData(this.userId, this.selectedIds)
        .subscribe(
          (data) => {
            this.auth.stopLoader();
            this.inp.checked = false;
            this.selectAll(false);
            alert(data["message"]);
            closeLeadsAssignModel();
          },
          (error) => {
            this.auth.stopLoader();
            alert(error);
          }
        );
    }
  }

  onChangeSize(event) {
    if (event != "") {
      this.pageSize = event;
    } else {
      this.pageSize = 20;
    }
  }

  getFilteredLeadsReportByPage(jsonObj) {
    this.auth.filterReport(jsonObj, this.page, this.pageSize).subscribe(
      (data) => {
        console.log(data);
        this.leadsReport = data["content"];
        this.config.totalItems = data["totalElements"];
        this.itemCount = data["totalElements"];
        this.config.itemsPerPage = this.pageSize;
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  pageChanged(event) {
    this.auth.startLoader();
    this.page = event;
    this.config.currentPage = event;
    this.getFilteredLeadsReportByPage(this.json_obj);
  }

  leadFeedback(leadsId, userId) {
    this.auth.startLoader();
    this.auth.getFeedbackOfLeads1(leadsId).subscribe(
      (data) => {
        this.feedbackList = data;
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
    this.getRecordingOfLeads(leadsId, userId);
  }

  activeUser(userList: any[]) {
    for (let user of userList) {
      if (user.deleted) {
        let index = userList.findIndex((x) => x.id === user.id);
        userList.splice(index, 1);
      }
    }
    return userList;
  }

  onChange(userId, leadsId) {
    this.auth.startLoader();
    this.selectedId = [];
    var arrObj = {};
    arrObj["leadsId"] = leadsId;
    this.selectedId.push(arrObj);
    if (userId != 0) {
      this.auth.assignLeads(userId, this.selectedId).subscribe(
        (data) => {
          this.auth.stopLoader();
          alert(data["message"]);
        },
        (error) => {
          this.auth.stopLoader();
        }
      );
    }
  }

  expendReport(leadId) {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.auth.startLoader();
      this.auth.getLeadReportById(leadId).subscribe(
        (data) => {
          this.leadsReport1 = data;
          this.auth.stopLoader();
          alert(data["message"]);
        },
        (error) => {
          this.auth.stopLoader();
        }
      );
    }
  }

  onsubmitFilter(event) {
    let formFilter = event.value;

    let projects: Array<any> = [];
    let sources: Array<any> = [];
    let assignedTo: Array<any> = [];
    let assignedBy: Array<any> = [];
    let status: Array<any> = [];
    let callStatus: Array<any> = [];
    let lastcallstatus: Array<any>[];

    if (formFilter.lAssignedBy.length > 0) {
      for (var str of formFilter.lAssignedBy) {
        assignedBy.push(str.id);
      }
    } else {
      assignedBy = [];
    }
    if (formFilter.lAssignedTo.length > 0) {
      for (var str of formFilter.lAssignedTo) {
        assignedTo.push(str.id);
      }
    } else {
      assignedTo = [];
    }
    if (formFilter.lStatus.length > 0) {
      for (var str of formFilter.lStatus) {
        status.push(str.name);
      }
    } else {
      status = [];
    }
    if (formFilter.lProject.length > 0) {
      for (var str of formFilter.lProject) {
        projects.push(str.name);
      }
    } else {
      projects = [];
    }
    if (formFilter.lSource.length > 0) {
      for (var str of formFilter.lSource) {
        sources.push(str.name);
      }
    } else {
      sources = [];
    }
    if (formFilter.lcStatus.length > 0) {
      for (var str of formFilter.lcStatus) {
          callStatus.push(str.name);
      }
    } else {
      callStatus = [];
    }

    // if (formFilter.lastStatus.length > 0) {
    //   for(var str of formFilter.lastStatus)
    //   {
    //     lastcallstatus.push(str.name);
    //   }
    // }
    // else
    // {
    //   lastcallstatus = [];
    // }

    var typeOfDate;
    if( formFilter.dcStatus.length > 0){
      typeOfDate =  formFilter.dcStatus[0].id;
    }else{
      typeOfDate = "";
    }

    this.json_obj = {
      reFromDate: this.auth.toTimestamp(formFilter.rdFrom),
      reToDate: this.auth.toTimestamp(formFilter.rdTo),
      asFromDate: this.auth.toTimestamp(formFilter.adFrom),
      asToDate: this.auth.toTimestamp(formFilter.adTo),
      csFromDate: this.auth.toTimestamp(formFilter.csdFrom),
      csToDate: this.auth.toTimestamp(formFilter.csdTo),
      statusList: status,
      callStatusList: callStatus,
      lastcallstatusList : lastcallstatus,
      typeOfDate: typeOfDate,
      assignedByIds: assignedBy,
      assignedToIds: assignedTo,
      projectsString: projects,
      transferred: formFilter.tLeads,
      sourceString: sources,
      orderBy: formFilter.orderBy,
      sortBy: formFilter.sortBy,
    };

    this.auth.startLoader();
    this.inp.checked = false;
    this.selectAll(false);
    this.page = 1;
    // this.filterFieldsData(event);
    this.getFilteredLeadsReportByPage(this.json_obj);
  }

  exportAsXLSX() {
    this.auth.startLoader();
    this.auth.filterLeadsReportForExcelExport(this.json_obj).subscribe(
      (data) => {
        this.auth.stopLoader();
        this.exportLeadsReportInExcel(data);
      },
      (error) => {
        console.log(error);
        this.auth.stopLoader();
      }
    );
  }

  private exportLeadsReportInExcel(leadsReport) {
    var exportList = [];
    console.log(leadsReport);
    for (let report of leadsReport) {
      let phone = report.leads.phone;
      if (!this.isValid) {
        phone = "******" + phone.substr(phone.length - 4);
      }
      var json_obj = {
        "Leads Id": report.leads.id,
        "Client Name": report.leads.name,
        "Client Email": report.leads.email,
        "Client Phone": phone,
        "Client Query": report.leads.queryInfo,
        "Lead Source": report.leads.source,
        "Project Name": report.leads.projectName,
        "Lead Status": report.status,
        "Call Status": report.callStatus,
        "lastcallstatus": report.lastcallstatus,
        "Last Updated date": this.auth.toString(report.updateDate),
        "Call Status Date": this.auth.toString(report.callStatusDate),
        "Lead Remark": report.message,
        "Recieved Date": this.auth.toString(report.leads.receivedOn),
        "Assigned Date": this.auth.toString(report.assignLeadsDate),
        "Visit Status": report.visitStatus == true ? "DONE" : "Not Visited",
        "Visit Date": this.auth.toString(report.visitDoneDate),
        "Created By": report.leads.createdBy
          ? (report.leads.createdBy.firstName
              ? report.leads.createdBy.firstName
              : "") +
            " " +
            (report.leads.createdBy.lastName
              ? report.leads.createdBy.lastName
              : "")
          : "",
        "Assigned To":
          (report.user.firstName ? report.user.firstName : "") +
          " " +
          (report.user.lastName ? report.user.lastName : ""),
        "Assigned By": report.assignedBy
          ? (report.assignedBy.firstName ? report.assignedBy.firstName : "") +
            " " +
            (report.assignedBy.lastName ? report.assignedBy.lastName : "")
          : "",
      };
      exportList.push(json_obj);
    }
    this.auth.exportAsExcelFile(exportList, "LeadsReportExcelSheet");
  }

  // Status

  onLeadsStatusSelect(item: any) {
    var projObj = {};
    this.callStatus = [];
    this.lastcallstatus = [];
    this.openString = null;
    this.declineString = null;
    this.closeString = null;
    projObj["id"] = item.id;
    projObj["name"] = item.name;
    this.selectedLeadStatus.push(projObj);
    for (var status of this.selectedLeadStatus) {
      if (status.name == "Open") {
        this.openString = "open";
      } else if (status.name == "Decline") {
        this.declineString = "decline";
      } else if (status.name == "Close") {
        this.callStatus = [];
      }
    }
    if (this.openString != null && this.declineString == null) {
      this.callStatus = this.openCallStatus;
    } else if (this.openString == null && this.declineString != null) {
      this.callStatus = this.declineCallStatus;
    } else if (this.openString != null && this.declineString != null) {
      this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    }

    // if (this.openString = null && this.declineString == null) {
    //     this.lastcallstatus = this.openlastcallstatus.concat(this.declinelastcallstatus);
    //   }
    //   else if (this.openString != null && this.declineString != null) {
    //       this.lastcallstatus = this.openlastcallstatus.concat(this.declinelastcallstatus);
    //     }
    //     else
    //     {
    //       this.lastcallstatus = this.openlastcallstatus.concat(this.declinelastcallstatus);
    //     }
  }

  onLeadsStatusDeSelect(item: any) {
    this.callStatus = [];
    this.openString = null;
    this.declineString = null;
    this.closeString = null;
    let index = this.selectedLeadStatus.findIndex((x) => x["id"] === item.id);
    this.selectedLeadStatus.splice(index, 1);
    for (var status of this.selectedLeadStatus) {
      if (status.name == "Open") {
        this.openString = "open";
      } else if (status.name == "Decline") {
        this.declineString = "decline";
      } else if (status.name == "Close") {
        this.closeString = "close";
      }
    }
    if (this.openString != null && this.declineString == null) {
      this.callStatus = this.openCallStatus;
    } else if (this.openString == null && this.declineString != null) {
      this.callStatus = this.declineCallStatus;
    } else if (this.openString != null && this.declineString != null) {
      this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    } else if (this.closeString != null) {
      this.callStatus = [];
    } else {
      this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    }

    // New Implementations

    // if (this.openString != null && this.declineString != null) {
    //     this.lastcallstatus = this.openlastcallstatus.concat(this.declinelastcallstatus);
    // }
    // else if (this.openString == null && this.declineString == null) {
    //     this.lastcallstatus = this.openlastcallstatus.concat(this.declinelastcallstatus);
    // }
    // else {
    //     this.lastcallstatus = this.openlastcallstatus.concat(this.declinelastcallstatus);
    // }


  }

  onLeadsStatusSelectAll(items: any) {
    this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    for (var item of items) {
      var projObj = {};
      projObj["id"] = item.id;
      projObj["name"] = item.name;
      this.selectedLeadStatus.push(projObj);
    }
  }

  onLeadsStatusDeSelectAll(items: any) {
    this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    this.selectedLeadStatus = [];
  }

  //Multiselect get data

  getProjects() {
    this.auth.getProjects().subscribe((data) => {
      var list: Array<any> = [];
      for (let project of data) {
        var obj: any = {};
        obj["id"] = project.id;
        obj["name"] = project.name;
        list.push(obj);
      }
      this.projectNameList = list;
    });
  }

  getSources() {
    this.auth.getSourceString().subscribe((data) => {
      var list: Array<any> = [];
      var id = 0;
      for (let source of data) {
        id = id + 1;
        var obj: any = {};
        obj["id"] = id;
        obj["name"] = source;
        list.push(obj);
      }
      this.sourceNameList = list;
    });
  }

  getAgents() {
    this.auth.getUsers().subscribe((data) => {
      var list: Array<any> = [];
      for (let user of data) {
        var obj: any = {};
        obj["id"] = user.id;
        obj["name"] = user.firstName + " " + user.lastName;
        list.push(obj);
      }
      this.userList = list;
    });
  }

  // Search leads by name, phone

  search(event) {
    this.auth.stopLoader();
    var text = event.target.querySelector("#search").value;
    if (text === "") {
      alert("Value is required.");
    } else {
      this.auth.searchClientNameOrPhone(text).subscribe(
        (data) => {
          this.leadsReport = data;
          this.config.totalItems = data.length;
          this.config.itemsPerPage = data.length;
          this.auth.stopLoader();
        },
        (error) => {
          console.log(error);
          this.auth.stopLoader();
        }
      );
    }
  }


  getclientbyid(event) {
    this.auth.stopLoader();
    var leadid = event.target.querySelector("#search12").value;
    if (leadid === "") {
      alert("Value is required.");
    } else {
      this.auth.searchClientById(leadid).subscribe(
        (data) => {
          this.leadsReport = data;
          this.config.totalItems = data.length;
          this.config.itemsPerPage = data.length;
          console.log(this.leadsReport);
          this.auth.stopLoader();
        },
        (error) => {
          console.log(error);
          this.auth.stopLoader();
        }
      );
    }
  }





  // exportAsXLSX1() {
  //   this.auth.startLoader();
  //   this.auth.filterLeadsReportForExcelExport(this.json_obj).subscribe(
  //     (data) => {
  //       this.auth.stopLoader();
  //       this.exportLeadsReportInExcel1(data);
  //     },
  //     (error) => {
  //       console.log(error);
  //       this.auth.stopLoader();
  //     }
  //   );
  // }

  // private exportLeadsReportInExcel1(feedbackList) {
  //   var exportList = [];
  //   console.log(feedbackList);
  //   for (let report of feedbackList) {
  //     let phone = report.leads.phone;
  //     if (!this.isValid) {
  //       phone = "******" + phone.substr(phone.length - 4);
  //     }
  //     var json_obj = {
  //       "Leads Id": report.leads.id,
  //       "Lead Status": report.status,
  //       "Call Status": report.callStatus,
  //       "Lead Remark": report.message,
  //       "Recieved Date": this.auth.toString(report.timeOfFeedback),
  //       "Assigned Date": this.auth.toString(report.callStatusDate),
  //       "Visit Date": this.auth.toString(report.visitDoneDate),
  //       "Virtual Meeting Done Date": this.auth.toString(report.virtualMeetingDoneDate),
  //       "Assigned To":
  //         (report.user.firstName ? report.user.firstName : "") +
  //         " " +
  //         (report.user.lastName ? report.user.lastName : ""),
  //     };
  //     exportList.push(json_obj);
  //   }
  //   this.auth.exportAsExcelFile(exportList, "LeadsReportExcelSheet");
  // }


}
